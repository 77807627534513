
export const getAccommodations = (state) => {
    return state.accommodations
}

export const getMaleAccommodations = (state) => {
    return state.maleAccommodations
}

export const getFemaleAccommodations = (state) => {
    return state.femaleAccommodations
}

export const getCurrentAccommodation = (state) => {
    return state.currentAccommodation
}

export const getTotalItems = (state) => {
    return state.totalItems
}