<template>
  <div v-if="currentFoodPoint == null">Loading</div>
  <div v-else class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0 d-flex justify-content-between">
            <h6>Editar Punto de Comida</h6>
          </div>
          <div class="card-body">
            <div class="col-sm mx-60">
              <form @submit.prevent="handleSubmit">
                <div class="row">
                  <div class="col-md-6">
                    <label class="form-control-label"> Nombre </label>
                    <input
                      v-model="currentFoodPoint.name"
                      :class="[
                        'form-control',
                        v.name.$error ? 'is-invalid' : '',
                      ]"
                      type="text"
                      placeholder=""
                    />
                    <p v-if="v.name.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.name.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                  <div class="col-md-6">
                    <label class="form-control-label"> Nº de teléfono </label>
                    <input
                      v-model="currentFoodPoint.phoneNumber"
                      :class="[
                        'form-control',
                        v.phoneNumber.$error ? 'is-invalid' : '',
                      ]"
                      type="text"
                      placeholder=""
                    />
                    <p
                      v-if="v.phoneNumber.$error"
                      class="invalid-feedback mb-0"
                    >
                      <small>
                        {{ v.phoneNumber.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-6">
                    <div>
                      <label class="form-control-label"> Email </label>
                      <input
                        v-model="currentFoodPoint.email"
                        :class="[
                          'form-control',
                          v.email.$error ? 'is-invalid' : '',
                        ]"
                        type="email"
                        placeholder=""
                      />
                      <p v-if="v.email.$error" class="invalid-feedback mb-0">
                        <small>
                          {{ v.email.$errors[0].$message }}
                        </small>
                      </p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label class="form-control-label"> Observaciones </label>
                    <textarea
                      v-model="currentFoodPoint.observations"
                      class="form-control"
                      type="text"
                      placeholder=""
                      rows="1"
                    />
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-6">
                    <label class="form-control-label"> Dirección </label>
                    <input
                      ref="streetRef"
                      v-model="currentFoodPoint.address"
                      :class="[
                        'form-control',
                        v.address.$error ? 'is-invalid' : '',
                      ]"
                      type="text"
                    />
                    <p v-if="v.address.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.address.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                  <div class="col-md-3">
                    <label class="form-control-label"> Latitud </label>
                    <input
                      v-model="currentFoodPoint.latitude"
                      :class="[
                        'form-control',
                        v.latitude.$error ? 'is-invalid' : '',
                      ]"
                      type="text"
                      placeholder=""
                    />
                    <p v-if="v.latitude.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.latitude.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                  <div class="col-md-3">
                    <label class="form-control-label"> Longitud </label>
                    <input
                      v-model="currentFoodPoint.longitude"
                      :class="[
                        'form-control',
                        v.longitude.$error ? 'is-invalid' : '',
                      ]"
                      type="text"
                      placeholder=""
                    />
                    <p v-if="v.longitude.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.longitude.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                </div>
                <div
                  ref="mapDivEdit"
                  class="mt-4"
                  style="width: 100%; height: 50vh"
                />
                <div class="mt-3">
                  <button
                    type="submit"
                    class="
                      float-end
                      btn btn-primary
                      text-white
                      bg-blue-600
                      rounded-lg
                      border border-primary
                      hover:bg-blue-900
                    "
                  >
                    Guardar
                  </button>
                  <button
                    class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
                    @click="handleCancel"
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */

import { onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { Loader } from "@googlemaps/js-api-loader";

const GOOGLE_MAPS_API_KEY = "AIzaSyDCiyEwDxu5Ts3ktGPqPq3SNIgh8K7i93M";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const mapDivEdit = ref(null);
    const streetRef = ref(null);

    const loader = new Loader({
      apiKey: GOOGLE_MAPS_API_KEY,
      version: "weekly",
      libraries: ["places"],
    });

    const getCurrentFoodPoint = async (id) => {
      const foodPointData = await store.dispatch(
        "foodPoint/getFoodPointById",
        id
      );
      return foodPointData;
    };

    const rules = computed(() => {
      return {
        name: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        phoneNumber: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        email: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        address: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        latitude: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        longitude: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
      };
    });

    const currentFoodPoint = computed(
      () => store.getters["foodPoint/getCurrentFoodPoint"]
    );

    onMounted(async () => {
      await getCurrentFoodPoint(route.params.id);
      await loader.load();

      const map = new google.maps.Map(mapDivEdit.value, {
        center: {
          lat: Number(currentFoodPoint.value.latitude),
          lng: Number(currentFoodPoint.value.longitude),
        },
        zoom: 15,
      });

      let markers = [];

      markers.push(
        new google.maps.Marker({
          map,
          title: currentFoodPoint.value.address,
          position: new google.maps.LatLng(
            Number(currentFoodPoint.value.latitude),
            Number(currentFoodPoint.value.longitude)
          ),
        })
      );

      const autocomplete = new google.maps.places.SearchBox(streetRef.value);

      autocomplete.addListener("places_changed", () => {
        const places = autocomplete.getPlaces();

        if (places.length === 0) {
          return;
        }

        // Clear out the old markers
        markers.forEach((marker) => {
          marker.setMap(null);
        });
        markers = [];

        const place = places[0];

        currentFoodPoint.value.address = place.formatted_address;

        currentFoodPoint.value.latitude = place.geometry.location
          .lat()
          .toString();
        currentFoodPoint.value.longitude = place.geometry.location
          .lng()
          .toString();

        map.setCenter(place.geometry.location);

        markers.push(
          new google.maps.Marker({
            map,
            title: place.name,
            position: new google.maps.LatLng(
              place.geometry.location.lat(),
              place.geometry.location.lng()
            ),
          })
        );
      });
    });

    const v$ = useVuelidate(rules, currentFoodPoint);
    const v = v$.value;

    const handleSubmit = () => {
      v$.value.$validate();
      if (!v$.value.$invalid) {
          console.log("test");
        updateFoodPoint(currentFoodPoint.value);
      }
    };

    const handleCancel = () => {
      router.push({ name: "Puntos-Comida", params: {} });
    };

    const updateFoodPoint = async (data) => {
      try {
        await store.dispatch("foodPoint/edit", data);
        console.log(data);
        await router.push({ name: "Puntos-Comida", params: {} });
      } catch (error) {
        console.log(error);
      }
    };

    return {
      currentFoodPoint,
      handleSubmit,
      handleCancel,
      v,
      mapDivEdit,
      streetRef,
    };
  },
};
</script>
