<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0 d-flex justify-content-between">
            <h6>Nuevo Responsable de Grupo</h6>
          </div>
          <div class="card-body">
            <div class="col-sm mx-60">
              <form @submit.prevent="handleSubmit">
                <div class="row">
                  <div class="col-md-6">
                    <label for="user_firstname" class="form-control-label">
                      Nombre
                    </label>
                    <input
                      id="user_firstname"
                      v-model="newUser.firstname"
                      :class="[
                        'form-control',
                        v.firstname.$error ? 'is-invalid' : '',
                      ]"
                      type="text"
                      placeholder=""
                    />
                    <p v-if="v.firstname.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.firstname.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                  <div class="col-md-6">
                    <label for="user_lastname" class="form-control-label">
                      Apellido
                    </label>
                    <input
                      v-model="newUser.lastname"
                      id="user_lastname"
                      :class="[
                        'form-control',
                        v.lastname.$error ? 'is-invalid' : '',
                      ]"
                      type="text"
                      placeholder=""
                    />
                    <p v-if="v.lastname.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.lastname.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-6">
                    <label for="user_email" class="form-control-label">
                      Email
                    </label>
                    <input
                      id="user_email"
                      v-model="newUser.email"
                      :class="[
                        'form-control',
                        v.email.$error ? 'is-invalid' : '',
                      ]"
                      type="email"
                      placeholder=""
                    />
                    <p v-if="v.email.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.email.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                  <div class="col-md-6">
                    <label for="user_password" class="form-control-label">
                      Contraseña
                    </label>
                    <input
                      v-model="newUser.password"
                      id="user_password"
                      :class="[
                        'form-control',
                        v.password.$error ? 'is-invalid' : '',
                      ]"
                      type="text"
                      placeholder=""
                    />
                    <p v-if="v.password.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.password.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-6">
                    <label for="user_address" class="form-control-label">
                      Dirección Postal
                    </label>
                    <textarea
                      id="user_address"
                      v-model="newUser.address"
                      :class="[
                        'form-control',
                        v.address.$error ? 'is-invalid' : '',
                      ]"
                      type="textarea"
                      placeholder=""
                      rows="3"
                    />
                    <p v-if="v.address.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.address.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                </div>
                <div class="mt-3">
                  <button
                    type="submit"
                    class="
                      float-end
                      btn btn-primary
                      text-white
                      bg-blue-600
                      border border-primary
                      rounded-lg
                      hover:bg-blue-900
                    "
                  >
                    Crear
                  </button>
                  <button
                    class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
                    @click="handleCancel"
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();

    const newUser = ref({
      email: "",
      firstname: "",
      lastname: "",
      password: "",
      address: "",
    });

    const rules = computed(() => {
      return {
        firstname: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        lastname: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        email: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        password: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        address: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
      };
    });

    const v$ = useVuelidate(rules, newUser);
    const v = v$.value;

    const handleSubmit = () => {
      v$.value.$validate();

      if (!v$.value.$invalid) {
        createUser(newUser.value);
      }
    };

    const handleCancel = () => {
      router.push({ name: "Responsables", params: {} });
    };

    const createUser = async (user) => {
      try {
        await store.dispatch("user/add", user);
        await router.push({ name: "Responsables" });
      } catch (error) {
        console.log(error);
      }
    };

    return {
      newUser,
      handleSubmit,
      handleCancel,
      v,
    };
  },
};
</script>
