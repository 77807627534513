<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0 d-flex justify-content-between">
            <h6>Cambiar contraseña</h6>
          </div>
          <div class="card-body">
            <div class="col-sm mx-60">
              <form @submit.prevent="handleSubmit">
                <div class="row">
                  <div class="col-md-6">
                    <label for="user_firstname" class="form-control-label">
                      Contraseña antigua
                    </label>
                    <input
                      id="user_firstname"
                      v-model="currentUser.oldPassword"
                      class="form-control"
                      type="text"
                      placeholder=""
                    />
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <label for="user_lastname" class="form-control-label">
                        Contraseña nueva
                      </label>
                      <input
                        v-model="currentUser.newPassword"
                        id="user_lastname"
                        class="form-control"
                        type="text"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <label for="user_lastname" class="form-control-label">
                        Repetir Contraseña nueva
                      </label>
                      <input
                        v-model="currentUser.newRetypedPassword"
                        id="user_lastname"
                        class="form-control"
                        type="text"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div class="mt-3">
                  <button
                    type="submit"
                    class="
                      float-end
                      btn btn-primary
                      text-white
                      bg-blue-600
                      border border-primary
                      rounded-lg
                      hover:bg-blue-900
                    "
                  >
                    Cambiar contraseña
                  </button>
                  <button
                    class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
                    @click="handleCancel"
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { ElMessage } from "element-plus";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const getCurrentUser = async (id) => {
      const userData = await store.dispatch("user/getCurrentUser", id);
      return userData;
    };

    onMounted(getCurrentUser(route.params.id));

    const currentUser = computed(() => store.getters["user/getCurrentUser"]);
    const errorMessage = computed(() => store.getters["user/getErrorMessage"]);

    const handleSubmit = () => {

        if(currentUser.value.newRetypedPassword !== currentUser.value.newPassword){
            ElMessage.error("Las contraseñas no coinciden");
          return;
        }

      changePassword(currentUser.value);
    };

    const changePassword = async (user) => {
      try {
        await store.dispatch("user/changePassword", user);
        if (errorMessage.value === null) {
          await router.push({ name: "Grupos" });
        }else{
            ElMessage.error(errorMessage.value);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const handleCancel = () => {
      router.push({ name: "Grupos" });
    };

    return {
      currentUser,
      handleSubmit,
      handleCancel,
    };
  },
};
</script>
