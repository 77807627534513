
export const getFoods = (state) => {
    return state.foods
}

export const getFoodParticipants = (state) => {
    return state.foodParticipants
}

export const getCurrentFood = (state) => {
    return state.currentFood
}

export const getFoodTypes = (state) => {
    return state.foodTypes
}

export const getTotalItems = (state) => {
    return state.totalItems
}

export const getTotalItemsParticipants = (state) => {
    return state.totalItemsParticipants
}