<template>
  <div v-if="foods === null">Loading</div>
  <div v-else class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0">
            <div class="row mb-2">
              <div class="col-auto">
                <div class="d-flex">
                  <el-select
                    v-model="foodTypeFilter"
                    size="large"
                    @change="handleSearch"
                  >
                    <el-option label="Todos" value=""></el-option>
                    <el-option
                      v-for="selectItem in foodTypes"
                      :key="selectItem['@id']"
                      :label="selectItem.name"
                      :value="selectItem['@id']"
                    />
                  </el-select>
                  <div>
                    <router-link
                      :to="{ name: 'Crear-Comida' }"
                      v-if="role == 'ROLE_SUPER_ADMIN'"
                      class="
                        btn btn-primary
                        text-white
                        bg-blue-600
                        rounded-lg
                        hover:bg-blue-900
                        ms-3
                      "
                      >Añadir Comida
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="
                        text-uppercase text-primary text-xs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Fecha
                    </th>
                    <th
                      class="
                        text-uppercase text-primary text-xs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Tipo
                    </th>
                    <th
                      class="
                        text-uppercase text-primary text-xs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Punto de recogida
                    </th>
                    <th class="text-custom opacity-7" v-if="role == 'ROLE_SUPER_ADMIN'"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in foods" :key="item['id']">
                    <td>
                      <div class="d-flex px-2 py-1">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">
                            {{ moment(item["date"]).format("DD/MM/YYYY") }}
                          </h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">
                        {{ item["type"] }}
                      </p>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">
                        {{ item["foodPoint"]["name"] }}
                      </p>
                    </td>
                    <td class="text-center" v-if="role == 'ROLE_SUPER_ADMIN'">
                      <router-link
                        :to="{
                          name: 'Actualizar-Comida',
                          params: { idFood: item['id'] },
                        }"
                      >
                        <a
                          class="btn btn-link px-3 mb-0 text-primary"
                          href="javascript:;"
                        >
                          Editar
                        </a>
                      </router-link>
                      <span class="text-primary">|</span>
                      <a
                        class="btn btn-link px-3 mb-0 text-secondary"
                        href="javascript:;"
                        @click="handleDelete(item['id'])"
                      >
                        Borrar
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="float-end my-3 me-4">
              <el-pagination
                background
                v-model:currentPage="currentPage"
                v-model:page-size="pageSize"
                :total="totalItems"
                :page-sizes="[10, 15, 20, 30]"
                layout="sizes, prev, pager, next"
                @current-change="handleGetFoods"
                @size-change="handleGetFoods"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ElMessageBox } from "element-plus";
import moment from "moment";


export default {
    
  setup() {
    const store = useStore();
    const route = useRoute();
    const currentPage = ref(1);
    const pageSize = ref(10);
    const search = ref("");
    const foodTypeFilter = ref("");
    const dateFilter = ref(null);

    const getFoods = async (params) => {
      const response = await store.dispatch("foodGroup/getFoods", params);
      return response;
    };

    const handleGetFoods = () => {
      getFoods({
        id: route.params.id,
        rows: pageSize.value,
        _page: currentPage.value,
      });
    };

    onMounted(() => {
      handleGetFoods();

      store.dispatch("theme/setRouteParent", {});
      store.dispatch("theme/setNameCurrentElement", null);
    });

    const foods = computed(() => store.getters["foodGroup/getFoods"]);
    const foodTypes = [
      { id: "Comida", name: "Comida" },
      { id: "Cena", name: "Cena" },
    ];
    const totalItems = computed(() => store.getters["foodGroup/getTotalItems"]);
    const role = computed(() => store.getters["auth/getRole"]);

    const handleSearch = () => {
      getFoods({
        rows: pageSize.value,
        _page: 1,
      });
    };

    const deleteFood = async (food) => {
      try {
        await store.dispatch("foodGroup/deleteFood", food);
        handleGetFoods();
      } catch (error) {
        console.log(error);
      }
    };

    const handleDelete = (item) => {
      ElMessageBox.confirm("¿Estás seguro que deseas eliminar el registro?")
        .then(() => {
          deleteFood(item);
        })
        .catch(() => {
          // catch error
        });
    };

    return {
      foods,
      role,
      handleDelete,
      currentPage,
      pageSize,
      totalItems,
      handleGetFoods,
      foodTypes,
      foodTypeFilter,
      search,
      handleSearch,
      dateFilter,
      moment,
    };
  },
};
</script>
