import axios from '@/utils/axios';
import jwtDecode from 'jwt-decode';

export const login = async ({ commit }, user) => {
    return await axios({
        method: "POST",
        url: '/login_web',
        data: {
            email: user.email,
            password: user.password
        }
    }).then(response => {
        if (response.data && response.data.token) {

            const { token } = response.data;

            const decoded = jwtDecode(token);
            // console.log(decoded);

            if (decoded.roles.includes('ROLE_PARTICIPANT')) {
                return { ok: false, message: 'Correo electrónico o contraseña no válidos' };
            }

            commit('loginUser', { user, token })
            localStorage.setItem('idToken', token);

            return { ok: true }
        } else {
            if (response === 'Invalid credentials.') {
                return { ok: false, message: 'Correo electrónico o contraseña no válidos' };
            }
            return { ok: false, message: response }
        }
    }).catch(error => {
        return { ok: false, message: error };
    })

}

export const getToken = () => {

    // const token = localStorage.getItem('idToken');
    // const decoded = jwtDecode(token);
    // console.log(decoded);

    return localStorage.getItem('idToken');

}

export const logout = ({ commit }) => {
    commit('logout');
}