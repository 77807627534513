<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0 d-flex justify-content-between">
            <h5 font-weight-bolder>Nuevo Pago</h5>
          </div>
          <div class="card-body">
            <div class="">
              <form @submit.prevent="handleSubmit">
                <div class="row">
                  <div class="col-md-6">
                    <label for="payment_concept" class="form-control-label">
                      Concepto
                    </label>
                    <input
                      id="payment_concept"
                      v-model="newPayment.concept"
                      class="form-control"
                      type="text"
                      placeholder=""
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="payment_quantity" class="form-control-label">
                      Cantidad
                    </label>
                    <input
                      v-model="newPayment.quantity"
                      id="payment_quantity"
                      class="form-control"
                      type="number"
                      placeholder=""
                      min="0"
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="payment_participant" class="form-control-label"
                      >Participante</label
                    >
                    <select
                      v-model="newPayment.participant"
                      id="payment_participant"
                      class="form-select"
                    >
                      <option :value="null">Ninguno</option>
                      <option
                        v-for="selectItem in participants"
                        :key="selectItem['@id']"
                        :value="selectItem['@id']"
                      >
                        {{ selectItem.firstname }} {{ selectItem.lastname }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label for="payment_date" class="form-control-label">
                      Fecha
                    </label>
                    <input
                      v-model="newPayment.paymentDate"
                      id="payment_date"
                      class="form-control"
                      type="date"
                      placeholder=""
                    />
                  </div>
                </div>
                <div class="mt-3">
                  <button
                    type="submit"
                    class="
                      float-end
                      btn btn-primary
                      text-white
                      bg-blue-600
                      border border-primary
                      rounded-lg
                      hover:bg-blue-900
                    "
                  >
                    Añadir Pago
                  </button>
                  <button
                    class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
                    @click="handleCancel"
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import moment from "moment";

export default {
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const groupId = route.params.id;

    const newPayment = ref({
      concept: "",
      quantity: "",
      participant: null,
      paymentDate: moment().format("YYYY-MM-DD"),
      groupRelated: `/api/groups/${groupId}`,
    });

    const getParticipants = async (params) => {
      const group = await store.dispatch(
        "participant/getParticipantsByGroup",
        params
      );
      return group;
    };

    onMounted(() => {
      getParticipants({
        id: groupId,
        pagination: false,
      });
    });

    const participants = computed(
      () => store.getters["participant/getParticipants"]
    );

    const handleSubmit = () => {
      const datePayment = moment(newPayment.value.paymentDate).format(
        "YYYY-MM-DD"
      );
      newPayment.value.paymentDate = datePayment;
      createPayment(newPayment.value);
    };

    const handleCancel = () => {
      router.push({
        name: "Detalle-Grupo",
        params: { id: groupId, tab: "payments" },
      });
    };

    const createPayment = async (payment) => {
      try {
        await store.dispatch("payment/add", payment);
        await router.push({
          name: "Detalle-Grupo",
          params: { id: groupId, tab: "payments" },
        });
      } catch (error) {
        console.log(error);
      }
    };

    return {
      participants,
      handleSubmit,
      handleCancel,
      newPayment,
    };
  },
};
</script>