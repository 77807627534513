import store from '../store';

const isAuthenticatedGuardAdmin = async (to, from, next) => {

  if (store.getters["auth/getRole"] === 'ROLE_SUPER_ADMIN' || store.getters["auth/getRole"] === 'ROLE_ADMIN') {
    next();
  } else if (store.getters["auth/getRole"] === 'ROLE_ACTIVITIES_ADMIN') {
    next({ name: 'Actividades' });
  }
}

export default isAuthenticatedGuardAdmin;
