<template>
  <div v-if="activities === null">Loading</div>
  <div v-else class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0">
            <div class="row mb-2">
              <div class="col">
                <div class="d-flex">
                  <input
                    type="text"
                    class="form-control me-3"
                    placeholder="Buscar"
                    v-model="search"
                    @keyup="handleSearch"
                  />
                  <div class="block">
                    <el-date-picker
                      v-model="dateFilter"
                      type="daterange"
                      unlink-panels
                      range-separator="a"
                      start-placeholder="Fecha inicio"
                      end-placeholder="Fecha fin"
                      format="DD/MM/YYYY"
                      :shortcuts="shortcuts"
                      @change="handleSearch"
                    />
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <div class="d-flex">
                  <el-select
                    v-model="activityTypeFilter"
                    size="large"
                    @change="handleSearch"
                  >
                    <el-option label="Todos" value=""></el-option>
                    <el-option
                      v-for="selectItem in activityTypes"
                      :key="selectItem['@id']"
                      :label="selectItem.name"
                      :value="selectItem['@id']"
                    />
                  </el-select>
                  <div v-if="role == 'ROLE_SUPER_ADMIN'">
                    <router-link
                      :to="{ name: 'Crear-Actividad' }"
                      class="
                        btn btn-primary
                        text-white
                        bg-blue-600
                        rounded-lg
                        hover:bg-blue-900
                        ms-3
                      "
                      >Añadir actividad
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="
                        text-uppercase text-primary text-xs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Nombre
                    </th>
                    <th
                      class="
                        text-uppercase text-primary text-xs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Código
                    </th>
                    <th
                      class="
                        text-uppercase text-primary text-xs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Fecha
                    </th>
                    <th
                      class="
                        text-uppercase text-primary text-xs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Capacidad
                    </th>
                    <th
                      class="
                        text-uppercase text-primary text-xs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Nº reservas
                    </th>
                    <th class="text-custom opacity-7"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in activities" :key="item['id']">
                    <td>
                      <div class="d-flex px-2 py-1">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">
                            <router-link
                              :to="{
                                name: 'Detalle-Actividad',
                                params: { id: item['id'] },
                              }"
                            >
                              {{ item["name"] }}
                            </router-link>
                          </h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">
                        {{ item["code"] }}
                      </p>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">
                        {{ moment(item["date"]).format("DD/MM/YYYY HH:mm") }}
                      </p>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">
                        {{ item['capacity']}}
                      </p>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">
                        {{ item['numSubscribed'] }}
                      </p>
                    </td>
                    <td class="text-center">
                      <router-link
                        :to="{
                          name: 'Actualizar-Actividad',
                          params: { id: item['id'] },
                        }"
                        v-if="role == 'ROLE_SUPER_ADMIN'"
                      >
                        <a
                          class="btn btn-link px-3 mb-0 text-primary"
                          href="javascript:;"
                        >
                          Editar
                        </a>
                      </router-link>
                      <span class="text-primary" v-if="role == 'ROLE_SUPER_ADMIN'">|</span>
                      <a
                        class="btn btn-link px-3 mb-0 text-primary"
                        href="javascript:;"
                        @click="handleExport(item)"
                        v-if="role == 'ROLE_ACTIVITIES_ADMIN' || role == 'ROLE_SUPER_ADMIN'"
                      >
                        Exportar
                      </a>
                      <span class="text-primary" v-if="role == 'ROLE_SUPER_ADMIN'" >|</span>
                      <a
                        class="btn btn-link px-3 mb-0 text-secondary"
                        href="javascript:;"
                        @click="handleDelete(item['id'])"
                        v-if="role == 'ROLE_SUPER_ADMIN'"
                      >
                        Borrar
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="float-end my-3 me-4">
              <el-pagination
                background
                v-model:currentPage="currentPage"
                v-model:page-size="pageSize"
                :total="totalItems"
                :page-sizes="[10, 15, 20, 30]"
                layout="sizes, prev, pager, next"
                @current-change="handleGetActivities"
                @size-change="handleGetActivities"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import { ElMessageBox } from "element-plus";
import moment from "moment";

export default {
  setup() {
    const store = useStore();
    const currentPage = ref(1);
    const pageSize = ref(10);
    const search = ref("");
    const activityTypeFilter = ref("");
    const dateFilter = ref(null);

    if (window.localStorage.getItem('search-activities') !== null) {
      search.value = JSON.parse(window.localStorage.getItem('search-activities'));
    }
    if (window.localStorage.getItem('date-filter-activities') !== null) {
      dateFilter.value = JSON.parse(window.localStorage.getItem('date-filter-activities'));
    }
    if (window.localStorage.getItem('type-filter-activities') !== null) {
      activityTypeFilter.value = JSON.parse(window.localStorage.getItem('type-filter-activities'));
    }

    const getActivities = async (params) => {
      const response = await store.dispatch("activity/getActivities", params);
      return response;
    };

    onMounted(() => {
      store.dispatch("activity/getActivityTypes");
      handleGetActivities();

      store.dispatch("theme/setRouteParent", {});
      store.dispatch("theme/setNameCurrentElement", null);
    });

    const activities = computed(() => store.getters["activity/getActivities"]);
    const activityTypes = computed(
      () => store.getters["activity/getActivityTypes"]
    );
    const totalItems = computed(() => store.getters["activity/getTotalItems"]);
    const role = computed(() => store.getters["auth/getRole"]);

    const handleGetActivities = () => {
      getActivities({
        rows: pageSize.value,
        _page: currentPage.value,
        name: search.value,
        code: search.value,
        activityType: activityTypeFilter.value,
        "date[after]":
          dateFilter.value !== null
            ? moment(dateFilter.value[0]).format("YYYY-MM-DD")
            : null,
        "date[before]":
          dateFilter.value !== null
            ? moment(dateFilter.value[1]).format("YYYY-MM-DD")
            : null,
      });
    };

    const handleSearch = () => {
      getActivities({
        rows: pageSize.value,
        _page: 1,
        name: search.value,
        code: search.value,
        activityType: activityTypeFilter.value,
        "date[after]":
          dateFilter.value !== null
            ? moment(dateFilter.value[0]).format("YYYY-MM-DD")
            : null,
        "date[before]":
          dateFilter.value !== null
            ? moment(dateFilter.value[1]).format("YYYY-MM-DD")
            : null,
      });
      window.localStorage.setItem('search-activities', JSON.stringify(search.value))
      window.localStorage.setItem('date-filter-activities', JSON.stringify(dateFilter.value))
      window.localStorage.setItem('type-filter-activities', JSON.stringify(activityTypeFilter.value))
    };

    const deleteActivity = async (activity) => {
      try {
        await store.dispatch("activity/deleteActivity", activity);
        handleGetActivities();
      } catch (error) {
        console.log(error);
      }
    };

    const handleDelete = (item) => {
      ElMessageBox.confirm("¿Estás seguro que deseas eliminar el registro?")
        .then(() => {
          deleteActivity(item);
        })
        .catch(() => {
          // catch error
        });
    };

    const handleExport = async (item) => {
      await store.dispatch("activity/exportActivity", item );
    };

    return {
      role,
      activities,
      handleDelete,
      currentPage,
      pageSize,
      totalItems,
      handleGetActivities,
      activityTypes,
      activityTypeFilter,
      search,
      handleSearch,
      handleExport,
      dateFilter,
      moment,
    };
  },
};
</script>
