import moment from "moment";

export const setFoods = (state, foods) => {
  state.foods = foods
  state.loading = false
}

export const setFoodParticipants = (state, foodParticipants) => {
  state.foodParticipants = foodParticipants
  state.loading = false
}

export const setTotalItems = (state, data) => {
  state.totalItems = data
}

export const setTotalItemsParticipants = (state, data) => {
  state.totalItemsParticipants = data
}

export const setCurrentFood = (state, food) => {
  if (food) {
    food.date ? food.date = moment(food.date).format('YYYY-MM-DD') : null;
  }

  state.currentFood = food
  state.loading = false
}

export const setFoodTypes = (state, foodTypes) => {
  state.foodTypes = foodTypes
  state.loading = false
}

export const setLoading = (state, loading) => {
  state.loading = loading
}
