
export const getFoodPoints = (state) => {
    return state.foodPoints
}

export const getCurrentFoodPoint = (state) => {
    return state.currentFoodPoint
}

export const getTotalItems = (state) => {
    return state.totalItems
}