import axios from '@/utils/axios';

export const getGroupTypes = async ({ commit }) => {
    await axios({
        method: "GET",
        url: '/group_types'
    })
        .then(response => {
            if (response.data) {
                const { data } = response;
                commit('setGroupTypes', data["hydra:member"]);
            }
        })
        .catch(e => {
            console.log(e);
        })
}

export const getGroups = async ({ commit }, params) => {

    await axios({
        method: "GET",
        url: '/groups',
        params: params
    })
        .then(response => {
            if (response.data) {
                const { data } = response;
                commit('setGroups', data["hydra:member"]);
                commit('setTotalItems', data['hydra:totalItems']);
            }
        })
        .catch(e => {
            console.log(e);
        });
};

export const getGroupsTotalParticipants = async ({ commit }, params) => {

    await axios({
        method: "GET",
        url: '/groups',
        params: params
    })
        .then(response => {
            if (response.data) {
                const { data } = response;
                commit('setGroupsTotalParticipants', data["hydra:member"]);
            }
        })
        .catch(e => {
            console.log(e);
        });
};

export const getGroupById = async ({ commit }, id) => {

    commit('setCurrentGroup', null);

    await axios({
        method: "GET",
        url: `/groups/${id}`
    })
        .then(response => {
            const { data } = response;
            commit('setCurrentGroup', data);
            commit('addPaymentModes', data);
        })
        .catch(e => {
            console.log(e);
        });
}

export const add = async (_, payload) => {

    return await axios({
        method: "POST",
        url: '/groups',
        data: payload
    })
        .then(response => {
            console.log(response);
        })
        .catch(e => {
            console.log(e);
        });
};

export const edit = async ({ commit }, payload) => {

    return await axios({
        method: "PUT",
        url: '/groups/'+ payload['id'],
        data: payload,
    })
        .then((response) => {
            console.log(response);
            commit('setLoading', false);
        })
        .catch(e => {
            console.log(e);
        });
};

export const deleteGroup = async ({ commit }, id) => {

    await axios({
        method: "DELETE",
        url: `/groups/${id}`
    })
        .then(response => {
            console.log(response);
            commit('setLoading', false);
        })
        .catch(e => {
            console.log(e);
        });
};

export const getPaymentsModeByGroup = async ({ commit }, id) => {

    await axios({
        method: "GET",
        url: `/groups/${id}/payments/total`
    })
        .then(response => {
            if (response.data) {
                const { data } = response;
                commit('setPaymentsMode', data["hydra:member"]);
            }
        })
        .catch(e => {
            console.log(e);
        });
}