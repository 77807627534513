<template>
  <div v-if="payments === null">Loading</div>
  <div v-else class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0">
            <div class="d-flex justify-content-between">
              <div></div>
              <div v-if="role == 'ROLE_SUPER_ADMIN'" class="text-end">
                <div class="d-flex">
                  <button
                    type="button"
                    class="
                      me-2
                      btn btn-primary
                      bg-white
                      text-primary
                      border border-primary
                    "
                    @click="showTotals"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Ver totales
                  </button>
                  <router-link
                    :to="{ name: 'Crear-Pago' }"
                    class="
                      btn btn-primary
                      text-white
                      bg-blue-600
                      rounded-lg
                      hover:bg-blue-900
                    "
                    >Añadir pago
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="
                        text-uppercase text-primary text-xs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Concepto
                    </th>
                    <th
                      class="
                        text-uppercase text-primary text-xs
                        font-weight-bolder
                        opacity-7
                        ps-2
                      "
                    >
                      Cantidad
                    </th>
                    <th
                      class="
                        text-uppercase text-primary text-xs
                        font-weight-bolder
                        opacity-7
                        ps-2
                      "
                    >
                      Peregrino
                    </th>
                    <th
                      v-if="role == 'ROLE_SUPER_ADMIN'"
                      class="text-custom opacity-7"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in payments" :key="item['id']">
                    <td>
                      <div class="d-flex px-2 py-1">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">
                            {{ item["concept"] }}
                          </h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">
                        {{ formatPrice(item["quantity"] )}} €
                      </p>
                    </td>
                    <td>
                      <p
                        v-if="item['participant']"
                        class="text-xs font-weight-bold mb-0"
                      >
                        {{ item["participant"]["firstname"] }}
                        {{ item["participant"]["lastname"] }}
                      </p>
                    </td>
                    <td v-if="role == 'ROLE_SUPER_ADMIN'" class="text-center">
                      <router-link
                        :to="{
                          name: 'Actualizar-Pago',
                          params: { id: groupId, idPay: item['id'] },
                        }"
                      >
                        <a
                          class="btn btn-link px-3 mb-0 text-primary"
                          href="javascript:;"
                        >
                          Editar
                        </a>
                      </router-link>
                      <span class="text-primary">|</span>
                      <a
                        class="btn btn-link px-3 mb-0 text-secondary"
                        href="javascript:;"
                        @click="handleDelete(item['id'])"
                      >
                        Borrar
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="float-end my-3 me-4">
              <el-pagination
                background
                v-model:currentPage="currentPage"
                v-model:page-size="pageSize"
                :total="totalItems"
                :page-sizes="[10, 15, 20, 30]"
                layout="sizes, prev, pager, next"
                @current-change="handleGetPayments"
                @size-change="handleGetPayments"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog v-model="dialogTableVisible" title="Totales por modo de pago">
      <el-table :data="paymentsMode">
        <el-table-column property="mode" label="Modo" width="150" />
        <el-table-column label="Total" width="180">
          <template #default="scope">
            <div>
              <span>{{ formatPrice(scope.row.total) }} €</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          property="numParticipants"
          label="Nª Participantes"
          width="200"
        />
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ElMessageBox } from "element-plus";

export default {
  setup() {
    const route = useRoute();
    const store = useStore();
    const currentPage = ref(1);
    const pageSize = ref(10);

    const groupId = route.params.id;
    const dialogTableVisible = ref(false);

    const getPayments = async (params) => {
      const response = await store.dispatch(
        "payment/getPaymentsByGroup",
        params
      );
      return response;
    };

    const getPaymentsMode = async (id) => {
      const paymentsMode = await store.dispatch(
        "group/getPaymentsModeByGroup",
        id
      );
      return paymentsMode;
    };

    const getGroupById = async (id) => {
      const group = await store.dispatch("group/getGroupById", id);
      return group;
    };

    onMounted(() => {
      handleGetPayments();
    });

    const payments = computed(() => store.getters["payment/getPayments"]);
    const role = computed(() => store.getters["auth/getRole"]);
    const paymentsMode = computed(() => store.getters["group/getPaymentsMode"]);
    const currentGroup = computed(() => store.getters["group/getCurrentGroup"]);
    const totalItems = computed(() => store.getters["payment/getTotalItems"]);

    const handleGetPayments = () => {
      getPayments({
        id: route.params.id,
        pagination: true,
        rows: pageSize.value,
        _page: currentPage.value,
      });
    };

    const deletePayment = async (payment) => {
      try {
        await store.dispatch("payment/deletePayment", payment);
        handleGetPayments();
      } catch (error) {
        console.log(error);
      }
    };

    const handleDelete = (item) => {
      ElMessageBox.confirm("¿Estás seguro que deseas eliminar el registro?")
        .then(() => {
          deletePayment(item);
        })
        .catch(() => {
          // catch error
        });
    };

    const showTotals = async () => {
      await getPaymentsMode(groupId);
      await getGroupById(route.params.id);
      dialogTableVisible.value = true;
    };

    const formatPrice = (value) => {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }

    return {
      payments,
      handleDelete,
      role,
      groupId,
      dialogTableVisible,
      paymentsMode,
      showTotals,
      currentGroup,
      currentPage,
      pageSize,
      totalItems,
      handleGetPayments,
      formatPrice
    };
  },
};
</script>
