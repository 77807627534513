
export default () => ({
    loading: true,
    participants: null,
    currentParticipant: null,
    registrationTypes: [],
    participantTypes: [],
    paymentMethods: [],
    statuses: [],
    allergies: [],
    setStatusesSelected: [],
    allergiesSelected: [],
    totalItems: 0,
    errorMessage: null,
})