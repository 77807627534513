<template>
  <div v-if="groups === null">Loading</div>
  <div v-else class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0">
            <div class="d-flex justify-content-between">
              <div class="col pb-3">
                <input
                  type="text"
                  class="form-control p-2 w-100"
                  placeholder="Buscar"
                  v-model="search"
                  @keyup="handleSearch"
                />
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <div>
                <h6>Total previsto: {{ exceptedTotal }}</h6>
                <h6>Total inscritos: {{ totalRegistered }}</h6>
              </div>
              <div class="text-end">
                <div class="d-flex">
                  <button
                    v-if="role == 'ROLE_SUPER_ADMIN'"
                    type="button"
                    class="
                      me-2
                      btn btn-primary
                      bg-white
                      text-primary
                      border border-primary
                    "
                    @click="showTotals"
                  >
                    Ver totales
                  </button>
                  <router-link
                    :to="{ name: 'Crear-Grupo' }"
                    class="
                      btn btn-primary
                      text-white
                      bg-blue-600
                      rounded-lg
                      hover:bg-blue-900
                    "
                    >Añadir grupo
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pt-2 pb-2">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="
                        text-uppercase text-primary text-xs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Nombre
                    </th>
                    <th
                      class="
                        text-center text-uppercase text-primary text-xs
                        font-weight-bolder
                        opacity-7
                        ps-2
                      "
                    >
                      Nº de peregrinos
                    </th>
                    <th
                      class="
                        text-center text-uppercase text-primary text-xs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Peregrinos inscritos
                    </th>
                    <th
                      class="
                        text-uppercase text-primary text-xs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Tipo
                    </th>
                    <th
                      class="
                        text-uppercase text-primary text-xs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Creado por
                    </th>
                    <th class="text-primary opacity-7"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in groups" :key="item['id']">
                    <td>
                      <div class="d-flex px-2 py-1">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">
                            <router-link
                              :to="{
                                name: 'Detalle-Grupo',
                                params: { id: item['id'], tab: 'participants' },
                              }"
                            >
                              {{ item["name"] }}
                            </router-link>
                          </h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p class="text-center text-xs font-weight-bold mb-0">
                        {{ item["totalPilgrims"] }}
                      </p>
                    </td>
                    <td>
                      <p class="text-center text-xs font-weight-bold mb-0">
                        {{ item["totalParticipants"] }}
                      </p>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">
                        {{ item["groupType"]["name"] }}
                      </p>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">
                        {{ item["user"]["firstname"] }}
                        {{ item["user"]["lastname"] }}
                      </p>
                    </td>
                    <td class="text-center">
                      <router-link
                        :to="{
                          name: 'Actualizar-Grupo',
                          // path: '/groups/' + item['id']
                          params: { id: item['id'] },
                        }"
                      >
                        <a class="btn btn-link px-3 mb-0 text-primary">
                          Editar
                        </a>
                      </router-link>
                      <span class="text-primary">|</span>
                      <a
                        class="btn btn-link px-3 mb-0 text-secondary"
                        href="javascript:;"
                        @click="handleDelete(item['id'])"
                      >
                        Borrar
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="float-end my-3 me-4">
              <el-pagination
                background
                v-model:currentPage="currentPage"
                v-model:page-size="pageSize"
                :total="totalItems"
                :page-sizes="[10, 15, 20, 30]"
                layout="sizes, prev, pager, next"
                @current-change="handleGetGroups"
                @size-change="handleGetGroups"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog v-model="showModalTotal" title="Totales por modo de pago">
      <el-table :data="paymentsModeTotal">
        <el-table-column property="mode" label="Modo" width="150" />
        <el-table-column label="Total" width="180">
          <template #default="scope">
            <div>
              <span>{{ formatPrice(scope.row.total) }} €</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          property="totalParticipants"
          label="Nª Participantes"
          width="200"
        />
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import { ElMessageBox } from "element-plus";

export default {
  setup() {
    const store = useStore();
    const currentPage = ref(1);
    const search = ref("");
    const pageSize = ref(10);
    const showModalTotal = ref(false);
    const exceptedTotal = ref(0);
    const totalRegistered = ref(0);

    const getGroups = async (params) => {
      const response = await store.dispatch("group/getGroups", params);
      return response;
    };
    const getGroupsTotalParticipants = async (params) => {
      const response = await store.dispatch(
        "group/getGroupsTotalParticipants",
        params
      );
      return response;
    };

    const getPaymentsMode = async (groups) => {
      const paymentsMode = await store.dispatch(
        "payment/getPaymentsModeTotal",
        groups
      );
      return paymentsMode;
    };

    onMounted(async () => {
      await handleGetGroups();
      await getGroupsTotalParticipants({ pagination: false });
      await store.dispatch("theme/setRouteParent", {});
      await store.dispatch("theme/setNameCurrentElement", null);

      groupsParticipantTotal.value.map((item) => {
        // console.log(item);
        exceptedTotal.value += item.totalPilgrims;
        totalRegistered.value += item.totalParticipants;
      });
    });

    const groups = computed(() => store.getters["group/getGroups"]);
    const groupsParticipantTotal = computed(
      () => store.getters["group/getGroupsTotalParticipants"]
    );
    const role = computed(() => store.getters["auth/getRole"]);
    const paymentsModeTotal = computed(
      () => store.getters["payment/getPaymentsModeGlobal"]
    );
    const totalItems = computed(() => store.getters["group/getTotalItems"]);

    const handleSearch = () => {
      store.dispatch("group/getGroups", {
        rows: pageSize.value,
        _page: 1,
        name: search.value,
      });
    };

    const handleGetGroups = async () => {
      await getGroups({
        pagination: true,
        rows: pageSize.value,
        _page: currentPage.value,
        name: search.value,
      });
    };

    const deleteGroup = async (participant) => {
      try {
        await store.dispatch("group/deleteGroup", participant);
        handleGetGroups();
      } catch (error) {
        console.log(error);
      }
    };

    const handleDelete = (item) => {
      ElMessageBox.confirm("¿Estás seguro que deseas eliminar el registro?")
        .then(() => {
          deleteGroup(item);
        })
        .catch(() => {
          // catch error
        });
    };

    const showTotals = async () => {
      await getPaymentsMode(groups.value);
      showModalTotal.value = true;
    };

    const formatPrice = (value) => {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    return {
      groups,
      handleDelete,
      search,
      handleSearch,
      showModalTotal,
      showTotals,
      paymentsModeTotal,
      role,
      currentPage,
      pageSize,
      totalItems,
      handleGetGroups,
      exceptedTotal,
      totalRegistered,
      formatPrice,
    };
  },
};
</script>
