import { createRouter, createWebHistory } from "vue-router";
import GroupList from "@/views/group/GroupList.vue";
import GroupCreate from "@/views/group/GroupCreate.vue";
import GroupUpdate from "@/views/group/GroupUpdate.vue";
import GroupDetails from "@/views/group/GroupDetails.vue";
import ParticipantList from "@/views/participant/ParticipantList.vue";
import ParticipantUpdate from "@/views/participant/ParticipantUpdate.vue";
import ParticipantCreate from "@/views/participant/ParticipantCreate.vue";
import PaymentList from "@/views/payment/PaymentList.vue";
import PaymentCreate from "@/views/payment/PaymentCreate.vue";
import PaymentUpdate from "@/views/payment/PaymentUpdate.vue";
import UserList from "@/views/user/UserList.vue";
import UserUpdate from "@/views/user/UserUpdate.vue";
import UserCreate from "@/views/user/UserCreate.vue";
import ChangePassword from "@/views/user/ChangePassword.vue";
import UserProfile from "@/views/user/UserProfile.vue";
import NoPageFound from "@/views/NoPageFound.vue";
import isAuthenticatedGuardSuperAdmin from '@/services/authGuard';
import isAuthenticatedGuardAdmin from '@/services/authGuardAdmin';
import isAuthenticatedGuardAdminActivities from '@/services/authGuardAdminActivities';
import ActivityList from "@/views/activity/ActivityList.vue";
import ActivityCreate from "@/views/activity/ActivityCreate.vue";
import ActivityUpdate from "@/views/activity/ActivityUpdate.vue";
import ActivityDetails from "@/views/activity/ActivityDetails.vue";
import AccommodationList from "@/views/accommodation/AccommodationList.vue";
import AccommodationCreate from "@/views/accommodation/AccommodationCreate.vue";
import AccommodationUpdate from "@/views/accommodation/AccommodationUpdate.vue";
import FoodPointList from "@/views/foodPoint/FoodPointList.vue";
import FoodPointCreate from "@/views/foodPoint/FoodPointCreate.vue";
import FoodPointUpdate from "@/views/foodPoint/FoodPointUpdate.vue";
import FoodCreate from "@/views/food/FoodCreate.vue";
import FoodUpdate from "@/views/food/FoodUpdate.vue";
import NotificationList from "@/views/notification/NotificationList.vue";
import NotificationCreate from "@/views/notification/NotificationCreate.vue";
import NotificationUpdate from "@/views/notification/NotificationUpdate.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/login",
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/LoginUser'),
  }, {
    path: '/front',
    name: 'Front',
    component: () => import('@/layouts/MainLayout'),
    children: [
      {
        path: "/users",
        beforeEnter: [isAuthenticatedGuardAdmin],
        component: () => import('@/layouts/EmptyLayout.vue'),
        children: [
          {
            path: "",
            name: "Responsables",
            component: UserList,
            beforeEnter: [isAuthenticatedGuardSuperAdmin]
          },
          {
            path: ":id",
            name: "Actualizar-Usuario",
            component: UserUpdate,
            beforeEnter: [isAuthenticatedGuardSuperAdmin]
          },
          {
            path: "add",
            name: "Crear-Usuario",
            component: UserCreate,
            beforeEnter: [isAuthenticatedGuardSuperAdmin]
          },
          {
            path: "edit/:id",
            name: "Cambiar-Contraseña",
            component: ChangePassword,
            beforeEnter: [isAuthenticatedGuardAdmin]
          },
        ]
      },
      {
        path: "/user/profile/edit/:id",
        name: "Editar-Perfil",
        component: UserProfile,
        beforeEnter: [isAuthenticatedGuardAdmin]
      },
      {
        path: "/groups",
        beforeEnter: [isAuthenticatedGuardAdmin],
        component: () => import('@/layouts/EmptyLayout.vue'),
        children: [
          {
            path: '',
            name: "Grupos",
            component: GroupList,
            beforeEnter: [isAuthenticatedGuardAdmin]
          },
          {
            path: 'add',
            name: 'Crear-Grupo',
            component: GroupCreate,
            beforeEnter: [isAuthenticatedGuardAdmin]
          },
          {
            path: 'edit/:id',
            name: 'Actualizar-Grupo',
            component: GroupUpdate,
            beforeEnter: [isAuthenticatedGuardAdmin]
          },
          {
            path: "detail/:id/:tab",
            name: "Detalle-Grupo",
            component: GroupDetails,
            beforeEnter: [isAuthenticatedGuardAdmin]
          },
          {
            path: ":id",
            name: "Participantes",
            component: ParticipantList,
            beforeEnter: [isAuthenticatedGuardAdmin]
          },
          {
            path: ':id/payments/add',
            name: 'Crear-Pago',
            component: PaymentCreate,
            beforeEnter: [isAuthenticatedGuardSuperAdmin]
          },
          {
            path: ':id/payments/:idPay',
            name: 'Actualizar-Pago',
            component: PaymentUpdate,
            beforeEnter: [isAuthenticatedGuardSuperAdmin]
          },
          {
            path: ':id/foods/add',
            name: 'Crear-Comida',
            component: FoodCreate,
            beforeEnter: [isAuthenticatedGuardSuperAdmin]
          },
          {
            path: ':id/foods/:idFood',
            name: 'Actualizar-Comida',
            component: FoodUpdate,
            beforeEnter: [isAuthenticatedGuardSuperAdmin]
          },
          {
            path: ':id/participants/add',
            name: 'Crear-Participante',
            component: ParticipantCreate,
            beforeEnter: [isAuthenticatedGuardAdmin]
          },
          {
            path: '/participant/edit/:id',
            name: 'Actualizar-Participante',
            component: ParticipantUpdate,
            beforeEnter: [isAuthenticatedGuardAdmin]
          },
        ]
      },
      {
        path: '/payments',
        name: 'Pagos',
        component: PaymentList,
        beforeEnter: [isAuthenticatedGuardSuperAdmin]
      },
      {
        path: "/activities",
        // beforeEnter: [isAuthenticatedGuardAdmin],
        component: () => import('@/layouts/EmptyLayout.vue'),
        children: [
          {
            path: '',
            name: "Actividades",
            component: ActivityList,
            beforeEnter: [isAuthenticatedGuardAdminActivities]
          },
          {
            path: 'add',
            name: 'Crear-Actividad',
            component: ActivityCreate,
            beforeEnter: [isAuthenticatedGuardSuperAdmin]
          },
          {
            path: "detail/:id/",
            name: "Detalle-Actividad",
            component: ActivityDetails,
            beforeEnter: [isAuthenticatedGuardAdminActivities]
          },
          {
            path: 'edit/:id',
            name: 'Actualizar-Actividad',
            component: ActivityUpdate,
            beforeEnter: [isAuthenticatedGuardSuperAdmin]
          },
        ]
      },
      {
        path: "/accommodations",
        beforeEnter: [isAuthenticatedGuardAdmin],
        component: () => import('@/layouts/EmptyLayout.vue'),
        children: [
          {
            path: '',
            name: 'Alojamientos',
            component: AccommodationList,
            beforeEnter: [isAuthenticatedGuardSuperAdmin]
          },
          {
            path: 'add',
            name: 'Crear-Alojamiento',
            component: AccommodationCreate,
            beforeEnter: [isAuthenticatedGuardSuperAdmin]
          },
          {
            path: "edit/:id",
            name: 'Actualizar-Alojamiento',
            component: AccommodationUpdate,
            beforeEnter: [isAuthenticatedGuardSuperAdmin]
          },
        ]
      },
      {
        path: "/food-points",
        beforeEnter: [isAuthenticatedGuardAdmin],
        component: () => import('@/layouts/EmptyLayout.vue'),
        children: [
          {
            path: '',
            name: 'Puntos-Comida',
            component: FoodPointList,
            beforeEnter: [isAuthenticatedGuardSuperAdmin]
          },
          {
            path: 'add',
            name: 'Crear-Punto-Comida',
            component: FoodPointCreate,
            beforeEnter: [isAuthenticatedGuardSuperAdmin]
          },
          {
            path: "edit/:id",
            name: 'Actualizar-Punto-Comida',
            component: FoodPointUpdate,
            beforeEnter: [isAuthenticatedGuardSuperAdmin]
          },
        ]
      },
      {
        path: "/notifications",
        beforeEnter: [isAuthenticatedGuardAdmin],
        component: () => import('@/layouts/EmptyLayout.vue'),
        children: [
          {
            path: '',
            name: 'Notificaciones',
            component: NotificationList,
            beforeEnter: [isAuthenticatedGuardSuperAdmin]
          },
          {
            path: 'add',
            name: 'Crear-Notificacion',
            component: NotificationCreate,
            beforeEnter: [isAuthenticatedGuardSuperAdmin]
          },
          {
            path: "edit/:id",
            name: 'Actualizar-Notificacion',
            component: NotificationUpdate,
            beforeEnter: [isAuthenticatedGuardSuperAdmin]
          },
        ]
      },

    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NoPageFound',
    component: NoPageFound
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, _, next) => {
  const publicPages = ['/login', '/'];
  const authRequired = !publicPages.includes(to.path);

  const loggedIn = localStorage.getItem('idToken');

  if (authRequired) {

    if (loggedIn === null) return next({ name: 'Login' })
    if (loggedIn) return next()

    return next({ name: 'misc-not-authorized' })

  } else {
    next();
  }
});

export default router;
