<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0">
            <div class="text-end mb-2">
              <button
                type="button"
                class="
                  me-2
                  btn btn-primary
                  bg-white
                  text-primary
                  border border-primary
                "
                v-if="role == 'ROLE_SUPER_ADMIN'"
                @click="modalSuscribeGroups"
              >
                Suscribir grupos
              </button>
              <button
                type="button"
                class="
                  me-2
                  btn btn-primary
                  border border-primary
                  text-white
                  bg-blue-600
                "
                href="javascript:;"
                v-if="role == 'ROLE_ACTIVITIES_ADMIN' || role == 'ROLE_SUPER_ADMIN'"
                @click="handleExport"
              >
                Exportar participantes
              </button>
            </div>
          </div>
          <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="
                        text-uppercase text-custom text-xxs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Nombre de Participante
                    </th>
                    <th
                      class="
                        text-uppercase text-custom text-xxs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      DNI
                    </th>
                    <th
                      class="
                        text-uppercase text-custom text-xxs text-center
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Fecha de suscripción
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in activityParticipants" :key="item['id']">
                    <td>
                      <div class="d-flex px-2 py-1">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">
                            {{ item["participant"]["firstname"] }}
                            {{ item["participant"]["lastname"] }}
                          </h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">
                        {{ item["participant"]["dni"] }}
                      </p>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0 text-center">
                        {{ moment(item["dateAdded"]).format("DD/MM/YYYY") }}
                      </p>
                    </td>
                    <td class="text-center" v-if="role == 'ROLE_SUPER_ADMIN'">
                      <a
                        class="btn btn-link text-danger text-gradient px-3 mb-0"
                        href="javascript:;"
                        @click="handleDelete(item['id'])"
                      >
                        <i class="far fa-trash-alt me-2" aria-hidden="true"></i
                        >Desuscribirse
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="float-end my-3 me-4">
              <el-pagination
                background
                v-model:currentPage="currentPage"
                v-model:page-size="pageSize"
                :total="totalItems"
                :page-sizes="[10, 15, 20, 30]"
                layout="sizes, prev, pager, next"
                @current-change="handleGetActivityParticipants"
                @size-change="handleGetActivityParticipants"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog v-model="modalSuscribe" title="Suscribir grupos">
      <div class="col-md-6 mt-1">
        <label for="notification_audience" class="form-control-label">
          Seleccionar Grupos
        </label>
        <el-select
          class="w-100"
          v-model="groupsSuscribed"
          size="large"
          multiple
        >
          <el-option
            v-for="selectItem in groups"
            :key="selectItem['id']"
            :label="selectItem.name"
            :value="selectItem['id']"
          />
        </el-select>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="modalSuscribe = false">Cancel</el-button>
          <el-button type="primary" @click="suscribeGroups">Confirm</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import moment from "moment";
import { ElMessageBox } from "element-plus";

export default {
  setup() {
    const route = useRoute();
    const store = useStore();
    const currentPage = ref(1);
    const pageSize = ref(10);
    const search = ref("");
    const modalSuscribe = ref(false);
    const groupsSuscribed = ref([]);

    const getActivityById = async (params) => {
      const response = await store.dispatch("activity/getActivityById", params);
      return response;
    };

    const getActivityParticipants = async (params) => {
      const response = await store.dispatch(
        "activity/getActivityParticipants",
        params
      );
      return response;
    };

    const getGroups = async (params) => {
      const response = await store.dispatch("group/getGroups", params);
      return response;
    };

    onMounted(async () => {
      getActivityById(route.params.id);
      await handleGetActivityParticipants();
    });

    const handleGetActivityParticipants = async () => {
      await getActivityParticipants({
        id: route.params.id,
        rows: pageSize.value,
        _page: currentPage.value,
        // name: search.value
      });
    };

    const currentActivity = computed(
      () => store.getters["activity/getCurrentActivity"]
    );

    const activityParticipants = computed(
      () => store.getters["activity/getActivityParticipants"]
    );

    const totalItems = computed(
      () => store.getters["activity/getTotalItemsParticipants"]
    );

    const role = computed(() => store.getters["auth/getRole"]);

    const groups = computed(() => store.getters["group/getGroups"]);

    watch(() => {
      if (currentActivity.value) {
        store.dispatch(
          "theme/setNameCurrentElement",
          currentActivity.value.name
        );
        store.dispatch("theme/setRouteParent", {
          route: "/activities",
          name: "Actividades",
        });
      }
    });

    const deleteActivityParticipant = async (participant) => {
      try {
        await store.dispatch("activity/deleteActivityParticipant", participant);
        handleGetActivityParticipants();
      } catch (error) {
        console.log(error);
      }
    };

    const handleDelete = (item) => {
      ElMessageBox.confirm("¿Estás seguro que deseas eliminar el registro?")
        .then(() => {
          deleteActivityParticipant(item);
        })
        .catch(() => {
          // catch error
        });
    };

    const modalSuscribeGroups = async () => {
      modalSuscribe.value = true;
      await getGroups({ pagination: false });
    };

    const suscribeGroups = async () => {
      try {
        await store.dispatch("activity/suscribeGroups", {
          activityId: route.params.id,
          groups: groupsSuscribed.value,
        });
        modalSuscribe.value = false;
        handleGetActivityParticipants();
      } catch (error) {
        console.log(error);
      }
    };

    const handleSearch = () => {};

    const handleExport = async () => {
      let data = {
        id: route.params.id,
        name: currentActivity.value.name
      };

      await store.dispatch("activity/exportActivity", data );
    };

    return {
      role,
      moment,
      activityParticipants,
      currentPage,
      pageSize,
      totalItems,
      search,
      handleSearch,
      handleGetActivityParticipants,
      handleDelete,
      modalSuscribeGroups,
      modalSuscribe,
      groups,
      groupsSuscribed,
      suscribeGroups,
      handleExport
    };
  },
};
</script>
