<template>
  <div v-if="accommodations === null">Loading</div>
  <div v-else class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0">
            <div class="row mb-2">
              <div class="col">
                <div class="d-flex">
                  <input
                    type="text"
                    class="form-control me-3"
                    placeholder="Buscar"
                    v-model="search"
                    @keyup="handleSearch"
                  />
                </div>
              </div>
              <div class="col-auto">
                <div class="d-flex">
                  <div>
                    <router-link
                      :to="{ name: 'Crear-Alojamiento' }"
                      class="
                        btn btn-primary
                        text-white
                        bg-blue-600
                        rounded-lg
                        hover:bg-blue-900
                        ms-3
                      "
                      >Añadir alojamiento
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="
                        text-uppercase text-primary text-xs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Nombre
                    </th>
                    <th
                      class="
                        text-uppercase text-primary text-xs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Telefono
                    </th>
                    <th class="text-custom opacity-7"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in accommodations" :key="item['id']">
                    <td>
                      <div class="d-flex px-2 py-1">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">
                            {{ item["name"] }}
                          </h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">
                        {{ item["phoneNumber"] }}
                      </p>
                    </td>
                    <td class="text-center">
                      <router-link
                        :to="{
                          name: 'Actualizar-Alojamiento',
                          params: { id: item['id'] },
                        }"
                      >
                        <a
                          class="btn btn-link px-3 mb-0 text-primary"
                          href="javascript:;"
                        >
                          Editar
                        </a>
                      </router-link>
                      <span class="text-primary">|</span>
                      <a
                        class="btn btn-link px-3 mb-0 text-secondary"
                        href="javascript:;"
                        @click="handleDelete(item['id'])"
                      >
                        Borrar
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="float-end my-3 me-4">
              <el-pagination
                background
                v-model:currentPage="currentPage"
                v-model:page-size="pageSize"
                :total="totalItems"
                :page-sizes="[10, 15, 20, 30]"
                layout="sizes, prev, pager, next"
                @current-change="handleGetAccommodations"
                @size-change="handleGetAccommodations"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import { ElMessageBox } from "element-plus";

export default {
  setup() {
    const store = useStore();
    const currentPage = ref(1);
    const pageSize = ref(10);
    const search = ref("");

    const getAccommodations = async (params) => {
      const response = await store.dispatch("accommodation/getAccommodations", params);
      return response;
    };

    onMounted(() => {
      handleGetAccommodations();

      store.dispatch("theme/setRouteParent", {});
      store.dispatch("theme/setNameCurrentElement", null);
    });

    const accommodations = computed(() => store.getters["accommodation/getAccommodations"]);
    const totalItems = computed(() => store.getters["accommodation/getTotalItems"]);

    const handleGetAccommodations = () => {
      getAccommodations({
        rows: pageSize.value,
        _page: currentPage.value,
        // name: search.value,
      });
    };

    const handleSearch = () => {
      getAccommodations({
        rows: pageSize.value,
        _page: 1,
        // name: search.value,
      });
    };

    const deleteAccommodation = async (accommodation) => {
      try {
        await store.dispatch("accommodation/deleteAccommodation", accommodation);
        handleGetAccommodations();
      } catch (error) {
        console.log(error);
      }
    };

    const handleDelete = (item) => {
      ElMessageBox.confirm("¿Estás seguro que deseas eliminar el registro?")
        .then(() => {
          deleteAccommodation(item);
        })
        .catch(() => {
          // catch error
        });
    };

    return {
      accommodations,
      handleDelete,
      currentPage,
      pageSize,
      totalItems,
      handleGetAccommodations,
      search,
      handleSearch,
    };
  },
};
</script>
