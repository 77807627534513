import { isArray, isObject, isUndefined, forEach } from "lodash";
import axios from '@/services/api';

const transformRelationToIri = (payload) => {
    forEach(payload, (value, property) => {
        if (isObject(value) && !isUndefined(value["@id"])) {
            payload[property] = value["@id"];
        }

        if (isArray(value)) payload[property] = transformRelationToIri(value);
    });

    return payload;
};

export default function (options) {

    const payload = options.data && options.data;
    if (isObject(payload) && payload["@id"] && options.method !== 'PUT')
        options.data = transformRelationToIri(payload)

    return axios(options).then(
        (response) => {

            if (!response.isAxiosError) return response

            if (response.response.status === 401) {
                return response.response.data.message
            }

            if (response.response.status === 404) {
                return response.response.data.title;
            }

            if (response.response.data.violations) {
                let errors = {};
                response.response.data.violations.map(
                    (violation) => (errors[violation.propertyPath] = violation.message)
                );

                return errors
            }

            const error = response.response.data["hydra:description"] || response.response.statusText;
            if (!response.response.data.violations) return error;
        });

}