import axios from '@/utils/axios';

export const getUsers = async ({ commit }, params) => {

    await axios({
        method: "GET",
        url: '/users',
        params: params
    })
        .then(response => {
            if (response.data) {
                const { data } = response;
                commit('setUsers', data["hydra:member"]);
                commit('setTotalItems', data['hydra:totalItems']);
            }
        })
        .catch(e => {
            console.log(e);
        });

};

export const getCurrentUser = async ({ commit }, id) => {

    commit('setCurrentUser', null)

    await axios({
        method: "GET",
        url: `/users/${id}`
    })
        .then(response => {
            if (response.data) {
                const { data } = response;
                commit('setCurrentUser', data);
            }
        })
        .catch(e => {
            console.log(e);
        });
}


export const add = async (_, payload) => {

    await axios({
        method: "POST",
        url: '/users',
        data: payload
    })
        .then(response => {
            console.log(response);
        })
        .catch(e => {
            console.log(e);
        });

};


export const edit = async (_, payload) => {

    return await axios({
        method: "PUT",
        url: '/users/' + payload['id'],
        data: payload
    })
        .then(response => {
            console.log(response);
        })
        .catch(e => {
            console.log(e);
        });
};

export const changePassword = async ({ commit }, payload) => {

    commit('setErrorMessage', null)

    await axios({
        method: "PUT",
        url: '/users/reset-password/' + payload['id'],
        data: payload
    })
        .then(response => {
            if (response.data.message) {
                commit('setErrorMessage', response.data.message);
            }
        }).catch(error => {
            console.log(error);
            commit('setLoading', false);
        });

};

export const deleteUser = async ({ commit }, id) => {

    await axios({
        method: "DELETE",
        url: `/users/${id}`
    })
        .then(response => {
            console.log(response);
            commit('setLoading', false);
        })
        .catch(e => {
            console.log(e);
        });
};

export const forgotPassword = async (_, email) => {

    return await axios({
        method: "GET",
        url: "/users/forgot-password",
        params: { email: email }
    })
        .then(response => {
            console.log(response);
        })
        .catch(e => {
            console.log(e);
        });
}