<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0 d-flex justify-content-between">
            <h6>Nueva comida</h6>
          </div>
          <div class="card-body">
            <div class="col-sm mx-60">
              <form @submit.prevent="handleSubmit">
                <div class="row">
                  <div class="col-md-6">
                    <label class="form-control-label">Tipo</label>
                    <select
                      v-model="newFood.type"
                      :class="[
                        'form-select',
                        v.type.$error ? 'is-invalid' : '',
                      ]"
                    >
                      <option
                        v-for="selectItem in foodTypes"
                        :key="selectItem['id']"
                        :value="selectItem['id']"
                      >
                        {{ selectItem.name }}
                      </option>
                    </select>
                    <p v-if="v.type.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.type.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                  <div class="col-md-6">
                    <label class="form-control-label"> Fecha </label>
                    <select
                      v-model="newFood.date"
                      :class="[
                        'form-select',
                        v.type.$error ? 'is-invalid' : '',
                      ]"
                    >
                      <option
                        v-for="selectItem in eventDates"
                        :key="selectItem['id']"
                        :value="selectItem['id']"
                      >
                        {{ selectItem.label }}
                      </option>
                    </select>
                    <p v-if="v.date.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.date.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-6">
                    <label class="form-control-label">Punto de recogida</label>
                    <select
                      v-model="newFood.foodPoint"
                      :class="[
                        'form-select',
                        v.foodPoint.$error ? 'is-invalid' : '',
                      ]"
                    >
                      <option
                        v-for="selectItem in foodPoints"
                        :key="selectItem['@id']"
                        :value="selectItem['@id']"
                      >
                        {{ selectItem.name }}
                      </option>
                    </select>
                    <p v-if="v.foodPoint.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.foodPoint.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                </div>
                <div class="mt-3">
                  <button
                    type="submit"
                    class="
                      float-end
                      btn btn-primary
                      text-white
                      bg-blue-600
                      rounded-lg
                      border border-primary
                      hover:bg-blue-900
                    "
                  >
                    Guardar
                  </button>
                  <button
                    class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
                    @click="handleCancel"
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import { onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { eventDates } from "@/utils/config";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const groupId = route.params.id;

    const newFood = ref({
      type: "",
      date: "",
      foodPoint: "",
      startTime: "",
      endTime: "",
      groupRelated: `/api/groups/${groupId}`,
    });

    const rules = computed(() => {
      return {
        type: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        date: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        foodPoint: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        }
      };
    });

    const getFoodPoints = async (params) => {
      const response = await store.dispatch("foodPoint/getFoodPoints", params);
      return response;
    };

    const handleGetFoodPoints = () => {
      getFoodPoints({
        pagination: false,
      });
    };

    onMounted(async () => {
      handleGetFoodPoints();
    });

    const foodPoints = computed(() => store.getters["foodPoint/getFoodPoints"]);

    const foodTypes = [
      { id: "Comida", name: "Comida" },
      { id: "Cena", name: "Cena" },
    ];

    const v$ = useVuelidate(rules, newFood);
    const v = v$.value;

    const handleSubmit = () => {
      v$.value.$validate();
      if (!v$.value.$invalid) {
        createFood(newFood.value);
      }
    };

    const handleCancel = () => {
      router.push({
        name: "Detalle-Grupo",
        params: { id: groupId, tab: "foods" },
      });
    };

    const createFood = async (food) => {
      try {
        await store.dispatch("foodGroup/add", food);
        await router.push({
          name: "Detalle-Grupo",
          params: { id: groupId, tab: "foods" },
        });
      } catch (error) {
        console.log(error);
      }
    };

    return {
      eventDates,
      foodTypes,
      newFood,
      handleSubmit,
      v,
      handleCancel,
      foodPoints,
    };
  },
};
</script>
