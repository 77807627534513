export const getUsers = (state) => {
    return state.users && state.users.filter(user => !user.roles.includes('ROLE_SUPER_ADMIN'));
}

export const getCurrentUser = (state) => {
    return state.currentUser
}

export const getErrorMessage = (state) => {
    return state.errorMessage
}

export const getTotalItems = (state) => {
    return state.totalItems
}