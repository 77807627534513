import axios from '@/utils/axios';
import FileSaver from "file-saver";

export const getActivityTypes = async ({ commit }) => {
  await axios({
    method: "GET",
    url: '/activity_types'
  })
    .then(response => {
      if (response.data) {
        const { data } = response;
        commit('setActivityTypes', data["hydra:member"]);
      }
    })
    .catch(e => {
      console.log(e);
    })
}

export const getActivities = async ({ commit }, params) => {

  await axios({
    method: "GET",
    url: '/activities',
    params: params
  })
    .then(response => {
      if (response.data) {
        const { data } = response;
        commit('setActivities', data["hydra:member"]);
        commit('setTotalItems', data['hydra:totalItems']);
      }
    })
    .catch(e => {
      console.log(e);
    });
};

export const getActivityById = async ({ commit }, id) => {

  commit('setCurrentActivity', null);

  await axios({
    method: "GET",
    url: `/activities/${id}`
  })
    .then(response => {
      const { data } = response;
      commit('setCurrentActivity', data);
    })
    .catch(e => {
      console.log(e);
    });
}

export const getActivityTranslate = async ({ commit }, params) => {

  commit('setCurrentActivity', null);

  await axios({
    method: "GET",
    url: "/activity/translate",
    params: params
  })
    .then(response => {
      console.log(response);
      const { data } = response;
      commit('setCurrentActivity', data);
    })
    .catch(e => {
      console.log(e);
    });
}

export const getActivityParticipants = async ({ commit }, params) => {

  commit('setActivityParticipants', []);
  const id = await params.id;
  delete params.id;

  commit('setCurrentActivity', null);

  await axios({
    method: "GET",
    url: `/activities/${id}/activity_participants`,
    params: params
  })
    .then(response => {
      const { data } = response;
      commit('setActivityParticipants', data["hydra:member"]);
      commit('setTotalItemsParticipants', data['hydra:totalItems']);
    })
    .catch(e => {
      console.log(e);
    });
}

export const add = async (_, payload) => {

  return await axios({
    method: "POST",
    url: '/activities',
    data: payload
  })
    .then(response => {
      console.log(response);
    })
    .catch(e => {
      console.log(e);
    });
};

export const edit = async ({ commit }, payload) => {

  return await axios({
    method: "PUT",
    url: '/activities/' + payload['id'],
    data: payload,
  })
    .then((response) => {
      console.log(response);
      commit('setLoading', false);
    })
    .catch(e => {
      console.log(e);
    });
};

export const deleteActivity = async ({ commit }, id) => {

  await axios({
    method: "DELETE",
    url: `/activities/${id}`
  })
    .then(response => {
      console.log(response);
      commit('setLoading', false);
    })
    .catch(e => {
      console.log(e);
    });
};

export const exportActivity = async (_, data) => {
  await axios({
    method: "GET",
    url: `/activity/export/${data.id}`,
    responseType: 'blob'
  })
    .then(response => {
        const blob = new Blob([response.data], { type: 'application/octet-stream' })
        FileSaver.saveAs(blob, data.name + new Date().toLocaleDateString() +".xlsx");
    })
    .catch(e => {
      console.log(e);
    });
}

export const deleteActivityParticipant = async ({ commit }, id) => {

  await axios({
    method: "DELETE",
    url: `/activity_participants/${id}`
  })
    .then(response => {
      console.log(response);
      commit('setLoading', false);
    })
    .catch(e => {
      console.log(e);
    });
}

export const editTranslate = async ({ commit }, payload) => {

  return await axios({
    method: "PUT",
    url: '/activity_translation/edit',
    data: payload,
  })
    .then((response) => {
      console.log(response);
      commit('setLoading', false);
      commit('setErrorMessageTranslate', response.data.message);
    })
    .catch(e => {
      console.log(e);
    });
};

export const suscribeGroups = async ({ commit }, payload) => {

  return await axios({
    method: "POST",
    url: '/activity/suscribed-groups',
    data: payload,
  })
    .then((response) => {
      console.log(response);
      commit('setLoading', false);
    })
    .catch(e => {
      console.log(e);
    });
}
