import axios from '@/utils/axios';

export const getNotifications = async ({ commit }, params) => {

    await axios({
        method: "GET",
        url: '/notifications',
        params: params
    })
        .then(response => {
            if (response.data) {
                const { data } = response;
                commit('setNotifications', data["hydra:member"]);
                commit('setTotalItems', data['hydra:totalItems']);
            }
        })
        .catch(e => {
            console.log(e);
        });
};

export const getNotificationById = async ({ commit }, id) => {

    commit('setCurrentNotification', null);

    await axios({
        method: "GET",
        url: `/notifications/${id}`
    })
        .then(response => {
            const { data } = response;
            commit('setCurrentNotification', data);
        })
        .catch(e => {
            console.log(e);
        });
}

export const add = async (_, payload) => {

    return await axios({
        method: "POST",
        url: '/notifications',
        data: payload
    })
        .then(response => {
            console.log(response);
        })
        .catch(e => {
            console.log(e);
        });
};

export const edit = async ({ commit }, payload) => {

    return await axios({
        method: "PUT",
        url: '/notifications/' + payload['id'],
        data: payload,
    })
        .then((response) => {
            console.log(response);
            commit('setLoading', false);
        })
        .catch(e => {
            console.log(e);
        });
};

export const deleteNotification = async ({ commit }, id) => {

    await axios({
        method: "DELETE",
        url: `/notifications/${id}`
    })
        .then(response => {
            console.log(response);
            commit('setLoading', false);
        })
        .catch(e => {
            console.log(e);
        });
};

export const send = async (_, payload) => {

    return await axios({
        method: "PUT",
        url: '/notifications/send',
        data: payload
    })
        .then(response => {
            console.log(response);
        })
        .catch(e => {
            console.log(e);
        });
}

export const sendNotificationNow = async (_, payload) => {

    return await axios({
        method: "PUT",
        url: '/notifications/send',
        data: payload
    })
        .then(response => {
            console.log(response);
        })
        .catch(e => {
            console.log(e);
        });
}

