
export const getPayments = (state) => {
    return state.payments
}

export const getCurrentPayment = (state) => {
    return state.currentPayment
}

export const getPaymentsModeGlobal = (state) => {
    return state.paymentsModeGlobal
}

export const getTotalItems = (state) => {
    return state.totalItems
}