import axios from '@/utils/axios';

export const getAccommodations = async ({ commit }, params) => {

    await axios({
        method: "GET",
        url: '/accommodations',
        params: params
    })
        .then(response => {
            if (response.data) {
                const { data } = response;
                commit('setAccommodations', data["hydra:member"]);
                commit('setTotalItems', data['hydra:totalItems']);
            }
        })
        .catch(e => {
            console.log(e);
        });
};

export const getMaleAccommodations = async ({ commit }, params) => {

    await axios({
        method: "GET",
        url: '/accommodations',
        params: params
    })
        .then(response => {
            if (response.data) {
                const { data } = response;
                commit('setMaleAccommodations', data["hydra:member"]);
            }
        })
        .catch(e => {
            console.log(e);
        });
};

export const getFemaleAccommodations = async ({ commit }, params) => {

    await axios({
        method: "GET",
        url: '/accommodations',
        params: params
    })
        .then(response => {
            if (response.data) {
                const { data } = response;
                commit('setFemaleAccommodations', data["hydra:member"]);
            }
        })
        .catch(e => {
            console.log(e);
        });
};

export const getAccommodationById = async ({ commit }, id) => {

    commit('setCurrentAccommodation', null);

    await axios({
        method: "GET",
        url: `/accommodations/${id}`
    })
        .then(response => {
            const { data } = response;
            commit('setCurrentAccommodation', data);
        })
        .catch(e => {
            console.log(e);
        });
}

export const add = async (_, payload) => {

    return await axios({
        method: "POST",
        url: '/accommodations',
        data: payload
    })
        .then(response => {
            console.log(response);
        })
        .catch(e => {
            console.log(e);
        });
};

export const edit = async ({ commit }, payload) => {

    return await axios({
        method: "PUT",
        url: '/accommodations/' + payload['id'],
        data: payload,
    })
        .then((response) => {
            console.log(response);
            commit('setLoading', false);
        })
        .catch(e => {
            console.log(e);
        });
};

export const deleteAccommodation = async ({ commit }, id) => {

    await axios({
        method: "DELETE",
        url: `/accommodations/${id}`
    })
        .then(response => {
            console.log(response);
            commit('setLoading', false);
        })
        .catch(e => {
            console.log(e);
        });
};