export const loginUser = (state, { user, idToken, refreshToken }) => {

    if (idToken) {
        localStorage.setItem('idToken', idToken)
        state.idToken = idToken
    }

    if (refreshToken) {
        localStorage.setItem('refreshToken', refreshToken)
        state.refreshToken = refreshToken
    }

    state.user = user
    state.isAuthenticated = true
}

export const userRole = (state, role) => {
    state.userRole = role
}

export const logout = (state) => {

    state.user = null
    state.idToken = null
    state.isAuthenticated = false

    localStorage.removeItem('idToken')
}