export const setFoodPoints = (state, foodPoints) => {
    state.foodPoints = foodPoints
    state.loading = false
}

export const setTotalItems = (state, data) => {
    state.totalItems = data
}

export const setCurrentFoodPoint = (state, foodPoint) => {

    state.currentFoodPoint = foodPoint
    state.loading = false
}

export const setLoading = (state, loading) => {
    state.loading = loading
}