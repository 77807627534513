<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0 d-flex justify-content-between">
            <h6>Nuevo grupo</h6>
          </div>
          <div class="card-body">
            <div class="col-sm mx-60">
              <form @submit.prevent="handleSubmit">
                <div class="row">
                  <div class="col-md-6">
                    <label for="group_name" class="form-control-label">
                      Nombre
                    </label>
                    <input
                      id="group_name"
                      v-model="newGroup.name"
                      :class="[
                        'form-control',
                        v.name.$error ? 'is-invalid' : '',
                      ]"
                      type="text"
                      placeholder=""
                    />
                    <p v-if="v.name.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.name.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                  <div class="col-md-6">
                    <label for="group_totalPilgrims" class="form-control-label">
                      Total de peregrinos
                    </label>
                    <input
                      id="group_totalPilgrims"
                      v-model="newGroup.totalPilgrims"
                      :class="[
                        'form-control',
                        v.totalPilgrims.$error ? 'is-invalid' : '',
                      ]"
                      type="number"
                      placeholder=""
                      min="0"
                    />
                    <p
                      v-if="v.totalPilgrims.$error"
                      class="invalid-feedback mb-0"
                    >
                      <small>
                        {{ v.totalPilgrims.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                </div>
                <div class="row mt-4" v-if="role == 'ROLE_SUPER_ADMIN'">
                  <div class="col-md-6">
                    <label class="form-control-label"
                      >Alojamiento masculino</label
                    >
                    <div class="row">
                      <el-select
                        v-model="newGroup.maleAccommodation"
                        filterable
                        placeholder="Selecionar alojamiento"
                        size="large"
                      >
                        <el-option
                          v-for="selectItem in maleAccommodations"
                          :key="selectItem['@id']"
                          :label="selectItem.name"
                          :value="selectItem['@id']"
                        />
                      </el-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label class="form-control-label"
                      >Alojamiento femenino</label
                    >
                    <div class="row">
                      <el-select
                        v-model="newGroup.femaleAccommodation"
                        filterable
                        placeholder="Selecionar alojamiento"
                        size="large"
                      >
                        <el-option
                          v-for="selectItem in femaleAccommodations"
                          :key="selectItem['@id']"
                          :label="selectItem.name"
                          :value="selectItem['@id']"
                        />
                      </el-select>
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-6">
                    <label for="group_groupType" class="form-control-label"
                      >Tipo</label
                    >
                    <select
                      v-model="newGroup.groupType"
                      id="group_groupType"
                      :class="[
                        'form-select',
                        v.groupType.$error ? 'is-invalid' : '',
                      ]"
                    >
                      <option
                        v-for="selectItem in groupTypes"
                        :key="selectItem['@id']"
                        :value="selectItem['@id']"
                      >
                        {{ selectItem.name }}
                      </option>
                    </select>
                    <p v-if="v.groupType.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.groupType.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                  <div
                    class="col-md-6"
                    v-if="newGroup.groupType == '/api/group_types/1'"
                  >
                    <label for="group_diocese" class="form-control-label">
                      Diocesis que la componen
                    </label>
                    <input
                      v-model="newGroup.diocese"
                      id="group_diocese"
                      class="form-control"
                      type="text"
                      placeholder=""
                    />
                  </div>
                  <div class="col-md-6">
                    <label class="form-control-label">
                      Saludo a Santiago
                    </label>
                    <input
                      v-model="newGroup.greetingsToSantiago"
                      class="form-control"
                      type="datetime-local"
                    />
                  </div>
                </div>
                <div class="card border mt-4 mb-2">
                  <div class="card-header pb-0 pt-4">
                    <h6>Datos de responsable</h6>
                  </div>
                  <div class="card-body pt-2">
                    <div class="row">
                      <div class="col-md-6">
                        <label for="manager_name" class="form-control-label">
                          Nombre
                        </label>
                        <input
                          id="manager_name"
                          v-model="newGroup.managers[0].name"
                          :class="[
                            'form-control',
                            v.managers[0].name.$error ? 'is-invalid' : '',
                          ]"
                          type="text"
                          placeholder=""
                        />
                        <p
                          v-if="v.managers[0].name.$error"
                          class="invalid-feedback mb-0"
                        >
                          <small>
                            {{ v.managers[0].name.$errors[0].$message }}
                          </small>
                        </p>
                      </div>
                      <div class="col-md-6">
                        <label
                          for="manager_lastname"
                          class="form-control-label"
                        >
                          Apellidos
                        </label>
                        <input
                          v-model="newGroup.managers[0].lastname"
                          id="manager_lastname"
                          :class="[
                            'form-control',
                            v.managers[0].lastname.$error ? 'is-invalid' : '',
                          ]"
                          type="text"
                          placeholder=""
                        />
                        <p
                          v-if="v.managers[0].lastname.$error"
                          class="invalid-feedback mb-0"
                        >
                          <small>
                            {{ v.managers[0].lastname.$errors[0].$message }}
                          </small>
                        </p>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-md-6">
                        <label
                          for="manager_lastname"
                          class="form-control-label"
                        >
                          Email
                        </label>
                        <input
                          id="manager_email"
                          v-model="newGroup.managers[0].email"
                          :class="[
                            'form-control',
                            v.managers[0].email.$error ? 'is-invalid' : '',
                          ]"
                          type="email"
                          placeholder=""
                        />
                        <p
                          v-if="v.managers[0].email.$error"
                          class="invalid-feedback mb-0"
                        >
                          <small>
                            {{ v.managers[0].email.$errors[0].$message }}
                          </small>
                        </p>
                      </div>
                      <div class="col-md-6">
                        <label
                          for="manager_lastname"
                          class="form-control-label"
                        >
                          Telefono
                        </label>
                        <input
                          id="manager_email"
                          v-model="newGroup.managers[0].phoneNumber"
                          :class="[
                            'form-control',
                            v.managers[0].phoneNumber.$error
                              ? 'is-invalid'
                              : '',
                          ]"
                          type="text"
                          placeholder=""
                        />
                        <p
                          v-if="v.managers[0].phoneNumber.$error"
                          class="invalid-feedback mb-0"
                        >
                          <small>
                            {{ v.managers[0].phoneNumber.$errors[0].$message }}
                          </small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card border pb-3 mt-4">
                  <div class="card-header pb-0 pt-4">
                    <h6>Datos de vice-responsable</h6>
                  </div>
                  <div class="card-body pt-2">
                    <div class="row">
                      <div class="col-md-6">
                        <label for="manager_name" class="form-control-label">
                          Nombre
                        </label>
                        <input
                          id="manager_name"
                          v-model="newGroup.managers[1].name"
                          :class="[
                            'form-control',
                            v.managers[1].name.$error ? 'is-invalid' : '',
                          ]"
                          type="text"
                          placeholder=""
                        />
                        <p
                          v-if="v.managers[1].name.$error"
                          class="invalid-feedback mb-0"
                        >
                          <small>
                            {{ v.managers[1].name.$errors[0].$message }}
                          </small>
                        </p>
                      </div>
                      <div class="col-md-6">
                        <label
                          for="manager_lastname"
                          class="form-control-label"
                        >
                          Apellidos
                        </label>
                        <input
                          v-model="newGroup.managers[1].lastname"
                          id="manager_lastname"
                          :class="[
                            'form-control',
                            v.managers[1].lastname.$error ? 'is-invalid' : '',
                          ]"
                          type="text"
                          placeholder=""
                        />
                        <p
                          v-if="v.managers[1].lastname.$error"
                          class="invalid-feedback mb-0"
                        >
                          <small>
                            {{ v.managers[1].lastname.$errors[0].$message }}
                          </small>
                        </p>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-md-6">
                        <label
                          for="manager_lastname"
                          class="form-control-label"
                        >
                          Email
                        </label>
                        <input
                          id="manager_email"
                          v-model="newGroup.managers[1].email"
                          :class="[
                            'form-control',
                            v.managers[1].email.$error ? 'is-invalid' : '',
                          ]"
                          type="email"
                          placeholder=""
                        />
                        <p
                          v-if="v.managers[1].email.$error"
                          class="invalid-feedback mb-0"
                        >
                          <small>
                            {{ v.managers[1].email.$errors[0].$message }}
                          </small>
                        </p>
                      </div>
                      <div class="col-md-6">
                        <label
                          for="manager_lastname"
                          class="form-control-label"
                        >
                          Telefono
                        </label>
                        <input
                          id="manager_email"
                          v-model="newGroup.managers[1].phoneNumber"
                          :class="[
                            'form-control',
                            v.managers[1].phoneNumber.$error
                              ? 'is-invalid'
                              : '',
                          ]"
                          type="text"
                          placeholder=""
                        />
                        <p
                          v-if="v.managers[1].phoneNumber.$error"
                          class="invalid-feedback mb-0"
                        >
                          <small>
                            {{ v.managers[1].phoneNumber.$errors[0].$message }}
                          </small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-3">
                  <button
                    type="submit"
                    class="
                      float-end
                      btn btn-primary
                      text-white
                      bg-blue-600
                      rounded-lg
                      border border-primary
                      hover:bg-blue-900
                    "
                  >
                    Crear
                  </button>
                  <button
                    class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
                    @click="handleCancel"
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();

    store.dispatch("group/getGroupTypes");

    const getMaleAccommodations = async (params) => {
      const response = await store.dispatch(
        "accommodation/getMaleAccommodations",
        params
      );
      return response;
    };

    const getFemaleAccommodations = async (params) => {
      const response = await store.dispatch(
        "accommodation/getFemaleAccommodations",
        params
      );
      return response;
    };

    const groupTypes = computed(() => store.getters["group/getGroupTypes"]);
    const maleAccommodations = computed(
      () => store.getters["accommodation/getMaleAccommodations"]
    );
    const femaleAccommodations = computed(
      () => store.getters["accommodation/getFemaleAccommodations"]
    );

    onMounted(async () => {
      await getMaleAccommodations({
        pagination: false,
        accommodationFor: "Masculino",
      });
      await getFemaleAccommodations({
        pagination: false,
        accommodationFor: "Femenino",
      });
    });

    const newGroup = ref({
      name: "",
      diocese: "",
      totalPilgrims: "",
      groupType: "",
      femaleAccommodation: null,
      maleAccommodation: null,
      managers: [
        {
          name: "",
          lastname: "",
          email: "",
          phoneNumber: "",
          type: "manager",
        },
        {
          name: "",
          lastname: "",
          email: "",
          phoneNumber: "",
          type: "sub-manager",
        },
      ],
    });

    const rules = computed(() => {
      return {
        name: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        totalPilgrims: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        groupType: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        managers: [
          {
            name: {
              required: helpers.withMessage(
                "Este campo es obligatorio",
                required
              ),
            },
            lastname: {
              required: helpers.withMessage(
                "Este campo es obligatorio",
                required
              ),
            },
            email: {
              required: helpers.withMessage(
                "Este campo es obligatorio",
                required
              ),
            },
            phoneNumber: {
              required: helpers.withMessage(
                "Este campo es obligatorio",
                required
              ),
            },
          },
          {
            name: {
              required: helpers.withMessage(
                "Este campo es obligatorio",
                required
              ),
            },
            lastname: {
              required: helpers.withMessage(
                "Este campo es obligatorio",
                required
              ),
            },
            email: {
              required: helpers.withMessage(
                "Este campo es obligatorio",
                required
              ),
            },
            phoneNumber: {
              required: helpers.withMessage(
                "Este campo es obligatorio",
                required
              ),
            },
          },
        ],
      };
    });

    const v$ = useVuelidate(rules, newGroup);
    const v = v$.value;

    const handleSubmit = () => {
      v$.value.$validate();
      if (!v$.value.$invalid) {
        createGroup(newGroup.value);
      }
    };

    const handleCancel = () => {
      router.push({ name: "Grupos", params: {} });
    };

    const createGroup = async (group) => {
      try {
        await store.dispatch("group/add", group);
        await router.push({ name: "Grupos", params: {} });
      } catch (error) {
        console.log(error);
      }
    };

    return {
      groupTypes,
      newGroup,
      handleSubmit,
      v,
      handleCancel,
      maleAccommodations,
      femaleAccommodations,
    };
  },
};
</script>
