
export const setPayments = (state, payments) => {
    state.payments = payments
    state.loading = false
}

export const setTotalItems = (state, data) => {
    state.totalItems = data
}

export const setCurrentPayment = (state, payment) => {

    if (payment === null) {
        state.currentPayment = null
        return
    }

    if (payment.participant) {
        payment.participant = payment.participant['@id']
    } else {
        payment.participant = null
    }

    payment.groupRelated = payment.groupRelated['@id']

    state.currentPayment = payment
    state.loading = false
}

export const setLoading = (state, loading) => {
    state.loading = loading
}

export const setPaymentsModeGlobal = (state, paymentsModeGlobal) => {
    state.paymentsModeGlobal = paymentsModeGlobal
}

export const addPaymentsGlobal = (state, groups) => {

    state.paymentsModeGlobal = [...state.paymentsModeGlobal,
    {
        mode: "De Grupo",
        total: totalPaymentsGrupalGlobal(groups),
        numParticipants: "_"
    },
    {
        mode: "",
        total: totalPaymentsGlobal(groups),
        numParticipants: ""
    }
    ]

}

const totalPaymentsGrupalGlobal = (groups) => {

    return groups.reduce((total, group) => {
        return total + Number(group.paymentsByGroup)
    }, 0)
}

const totalPaymentsGlobal = (groups) => {

    return groups.reduce((total, group) => {
        return total + Number(group.totalPayments)
    }, 0)
}