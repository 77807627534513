export const setUsers = (state, users) => {
    state.users = users;
}

export const setTotalItems = (state, data) => {
    state.totalItems = data
}

export const setCurrentUser = (state, user) => {
    state.currentUser = user;
    state.loading = false
}

export const setLoading = (state, loading) => {
    state.loading = loading
}

export const setErrorMessage = (state, errorMessage) => {
    state.errorMessage = errorMessage
}