export const setNotifications = (state, notifications) => {
  state.notifications = notifications
  state.loading = false
}

export const setTotalItems = (state, data) => {
  state.totalItems = data
}

export const setCurrentNotification = (state, notification) => {

  state.currentNotification = notification
  state.loading = false
}

export const setLoading = (state, loading) => {
  state.loading = loading
}
