
import { createApp } from "vue"
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import "./assets/scss/custom.scss";
import "./assets/scss/customElement.scss";
import Theme from "./theme";
import ElementPlus from 'element-plus'
import interceptors from '@/services/interceptors';

interceptors(router);
createApp(App)
    .use(store)
    .use(router)
    .use(Theme)
    .use(ElementPlus)
    .mount("#app");
