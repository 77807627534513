
export const getActivities = (state) => {
    return state.activities
}

export const getActivityParticipants = (state) => {
    return state.activityParticipants
}

export const getCurrentActivity = (state) => {
    return state.currentActivity
}

export const getActivityTypes = (state) => {
    return state.activityTypes
}

export const getTotalItems = (state) => {
    return state.totalItems
}

export const getTotalItemsParticipants = (state) => {
    return state.totalItemsParticipants
}

export const getErrorMessageTranslate = (state) => {
    return state.errorMessageTranslate
}