import axios from '@/utils/axios';

export const getParticipantsByGroup = async ({ commit }, params) => {

    commit('setParticipants', []);
    const id = await params.id;
    delete params.id;

    await axios({
        method: "GET",
        url: `/groups/${id}/participants`,
        params: params
    })
        .then(response => {
            if (response.data) {
                const { data } = response;
                commit('setParticipants', data["hydra:member"]);
                commit('setTotalItems', data['hydra:totalItems']);
            }

        })
        .catch(e => {
            console.log(e);
        });
}

export const getCurrentParticipant = async ({ commit }, id) => {

    commit('setCurrentParticipant', null)

    await axios({
        method: "GET",
        url: `/participants/${id}`
    })
        .then(response => {
            if (response.data) {
                const { data } = response;
                commit('setCurrentParticipant', data)
            }
        })
        .catch((e) => {
            console.log(e);
        });

};

export const add = async ({ commit }, payload) => {

    await axios({
        method: "POST",
        url: '/participants',
        data: payload
    })
        .then(response => {
            if (response.data) {
                const { data } = response;
                commit('setErrorMessage', null);
                return data;
            } else {
                if (response.email) {
                    commit('setErrorMessage', response.email);
                }
                if (response.dni) {
                    commit('setErrorMessage', response.dni);
                }
            }
        })
        .catch((e) => {
            console.log(e);
        });
};

export const edit = async ({ commit }, payload) => {

    const wayId = await payload.way ? payload.way.id : null;

    if (payload.goWay === 'no' && wayId !== null) {
        payload.way = null;
    }

    return await axios({
        method: "PUT",
        url: '/participants/' + payload['id'],
        data: payload
    })
        .then(response => {

            if (response.data) {
                const { data } = response;
                commit('setErrorMessage', null);

                if (payload.goWay === 'no' && wayId !== null && wayId !== undefined) {
                    axios.delete(`/ways/${wayId}`)
                }

                return data;
            } else {
                if (response.email) {
                    commit('setErrorMessage', response.email);
                }
                if (response.dni) {
                    commit('setErrorMessage', response.dni);
                }
            }


        })
        .catch((e) => {
            console.log(e);
        });
};

export const deleteParticipant = async ({ commit }, id) => {

    await axios({
        method: "DELETE",
        url: `/participants/${id}`
    })
        .then(response => {
            console.log(response);
            commit('setLoading', false);
        })
        .catch(e => {
            console.log(e);
        });
};


export const getRegistrationTypes = async ({ commit }) => {

    await axios({
        method: "GET",
        url: "/registration_types"
    })
        .then(response => {
            if (response.data) {
                const { data } = response;
                commit('setRegistrationTypes', data["hydra:member"]);
            }
        })
        .catch((e) => {
            console.log(e);
        });
};

export const getParticipantTypes = async ({ commit }) => {

    await axios({
        method: "GET",
        url: "/participant_types"
    })
        .then(response => {
            if (response.data) {
                const { data } = response;
                commit('setParticipantTypes', data["hydra:member"]);
            }
        })
        .catch((e) => {
            console.log(e);
        });
};

export const getPaymentMethods = async ({ commit }) => {

    await axios({
        method: "GET",
        url: "/payment_methods"
    })
        .then(response => {
            if (response.data) {
                const { data } = response;
                commit('setPaymentMethods', data["hydra:member"]);
            }
        })
        .catch((e) => {
            console.log(e);
        });
};

export const getStatuses = async ({ commit }) => {

    await axios({
        method: "GET",
        url: "/statuses"
    })
        .then(response => {
            if (response.data) {
                const { data } = response;
                commit('setStatuses', data["hydra:member"]);
            }
        })
        .catch((e) => {
            console.log(e);
        });
};

export const getAllergies = async ({ commit }) => {

    await axios({
        method: "GET",
        url: "/allergies"
    })
        .then(response => {
            if (response.data) {
                const { data } = response;
                commit('setAllergies', data["hydra:member"]);
            }
        })
        .catch((e) => {
            console.log(e);
        });
};


export const getParticipantStatuses = async ({ commit }, id) => {

    await axios({
        method: "GET",
        url: `/participants/${id}/participant_statuses`
    })
        .then(response => {
            if (response.data) {
                const { data } = response;
                commit('setStatusesSelected', data["hydra:member"]);
            }
        })
        .catch(e => {
            console.log(e);
        });
}

export const addStatus = async (_, payload) => {

    await axios({
        method: "POST",
        url: '/participant_statuses',
        data: payload
    })
        .then(response => {
            console.log(response);
        })
        .catch((e) => {
            console.log(e);
        });
}

export const removeStatus = async ({ commit }, payload) => {

    await axios({
        method: "DELETE",
        url: `/participant_statuses/${payload.id}`
    })
        .then(response => {
            console.log(response);
            commit('removeStatusesSelected', payload["@id"]);
        })
        .catch((e) => {
            console.log(e);
        });
}

export const getParticipantAllergies = async ({ commit }, id) => {

    await axios({
        method: "GET",
        url: `/participants/${id}/participant_allergies`
    })
        .then(response => {
            if (response.data) {
                const { data } = response;
                commit('setAllergiesSelected', data["hydra:member"]);
            }

        })
        .catch(e => {
            console.log(e);
        });
}

export const addAllergy = async (_, payload) => {

    await axios({
        method: "POST",
        url: '/participant_allergies',
        data: payload
    })
        .then(response => {
            console.log(response);
        })
        .catch((e) => {
            console.log(e);
        });
}

export const removeAllergy = async ({ commit }, payload) => {

    await axios({
        method: "DELETE",
        url: `/participant_allergies/${payload.id}`
    })
        .then(response => {
            console.log(response);
            commit('removeAllergiesSelected', payload["@id"]);
        })
        .catch((e) => {
            console.log(e);
        });
}

export const deleteWay = async ({ commit }, id) => {

    await axios({
        method: "DELETE",
        url: `/ways/${id}`
    })
        .then(response => {
            console.log(response);
            commit('setLoading', false);
        })
        .catch(e => {
            console.log(e);
        });
};