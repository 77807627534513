import { createStore } from "vuex";

import group from "./group";
import participant from "./participant";
import auth from "./auth";
import user from "./user";
import theme from "./theme";
import payment from "./payment";
import activity from "./activity";
import accommodation from "./accommodation";
import foodPoint from "./foodPoint";
import foodGroup from "./foodGroup";
import notification from "./notification";

const store = createStore({
  modules: {
    auth,
    group,
    participant,
    user,
    theme,
    payment,
    activity,
    accommodation,
    foodPoint,
    foodGroup,
    notification
  }
});

export default store;
