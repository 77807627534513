<template>
  <div v-if="currentNotification == null">Loading</div>
  <div v-else class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0 d-flex justify-content-between">
            <h6>Editar Notificación</h6>
          </div>
          <div class="card-body">
            <div class="col-sm mx-60">
              <form @submit.prevent="handleSubmit">
                <div class="row">
                  <div class="col-md-12">
                    <label for="notification_title" class="form-control-label">
                      Titulo
                    </label>
                    <input
                      id="notification_title"
                      v-model="currentNotification.title"
                      :class="[
                        'form-control',
                        v.title.$error ? 'is-invalid' : '',
                      ]"
                      type="text"
                      placeholder=""
                      :disabled="currentNotification.sentAt != null"
                    />
                    <p v-if="v.title.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.title.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-12">
                    <label
                      for="notification_message"
                      class="form-control-label"
                    >
                      Mensaje
                    </label>
                    <textarea
                      id="notification_message"
                      v-model="currentNotification.message"
                      :class="[
                        'form-control',
                        v.message.$error ? 'is-invalid' : '',
                      ]"
                      type="textarea"
                      placeholder=""
                      rows="3"
                      :disabled="currentNotification.sentAt != null"
                    />
                    <p v-if="v.message.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.message.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label
                      for="notification_audience"
                      class="form-control-label"
                    >
                      Audiencia
                    </label>
                    <select
                      v-model="currentNotification.audience"
                      :class="[
                        'form-select',
                        v.audience.$error ? 'is-invalid' : '',
                      ]"
                      :disabled="currentNotification.sentAt != null"
                    >
                      <option
                        v-for="selectItem in audiences"
                        :key="selectItem['id']"
                        :value="selectItem['value']"
                      >
                        {{ selectItem.name }}
                      </option>
                    </select>
                    <p v-if="v.audience.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.audience.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                  <div class="col-md-6">
                    <label for="notification_type" class="form-control-label">
                      Tipo
                    </label>
                    <select
                      v-model="currentNotification.type"
                      :class="[
                        'form-select'
                      ]"
                      :disabled="currentNotification.sentAt != null"
                    >
                      <option
                        v-for="selectItem in types"
                        :key="selectItem['id']"
                        :value="selectItem['value']"
                      >
                        {{ selectItem.name }}
                      </option>
                    </select>
                  </div>
                  <div class="row">
                    <div
                      v-if="currentNotification.audience === 'grupos'"
                      class="col-md-6 mt-1"
                    >
                      <label
                        for="notification_audience"
                        class="form-control-label"
                      >
                        Seleccionar Grupos
                      </label>
                      <el-select
                        class="w-100"
                        v-model="currentNotification.groupsTo"
                        size="large"
                        @change="handleGetGroups"
                        multiple
                        :disabled="currentNotification.sentAt != null"
                      >
                        <el-option
                          v-for="selectItem in groups"
                          :key="selectItem['id']"
                          :label="selectItem.name"
                          :value="selectItem['id']"
                        />
                      </el-select>
                    </div>
                  </div>
                </div>
                <div class="mt-3">
                  <button
                    type="submit"
                    class="
                      float-end
                      btn btn-primary
                      text-white
                      bg-blue-600
                      border border-primary
                      rounded-lg
                      hover:bg-blue-900
                    "
                    v-if="currentNotification.sentAt == null"
                  >
                    Guardar
                  </button>
                  <button
                    class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
                    @click.prevent="handleCancel"
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { audiences, types } from "../../utils/config";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const groupsSelected = ref(null);

    const getGroups = async (params) => {
      const response = await store.dispatch("group/getGroups", params);
      return response;
    };

    const groups = computed(() => store.getters["group/getGroups"]);

    const handleGetGroups = async () => {
      await getGroups({
        pagination: false,
      });
    };

    const rules = computed(() => {
      return {
        title: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        audience: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        message: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
      };
    });

    const getCurrentNotification = async (id) => {
      const notificationData = await store.dispatch(
        "notification/getNotificationById",
        id
      );
      return notificationData;
    };

    const currentNotification = computed(
      () => store.getters["notification/getCurrentNotification"]
    );

    const handleSubmit = () => {
      v$.value.$validate();
      console.log(v$.value);
      if (!v$.value.$invalid) {
        updateNotification(currentNotification.value);
      }
    };

    const updateNotification = async (notification) => {
      try {
        await store.dispatch("notification/edit", notification);
        await router.push({ name: "Notificaciones", params: {} });
      } catch (error) {
        console.log(error);
      }
    };

    const handleSendNotification = async () => {
      await store.dispatch("notification/send", currentNotification.value);
      await router.push({ name: "Notificaciones" });
    };

    const handleCancel = () => {
      router.push({ name: "Notificaciones", params: {} });
    };

    onMounted(async () => {
      await getCurrentNotification(route.params.id);
    });

    const v$ = useVuelidate(rules, currentNotification);
    const v = v$.value;

    watch(
      () => currentNotification.value && currentNotification.value.audience,
      () => {
        if (
          currentNotification.value &&
          currentNotification.value.audience === "grupos"
        ) {
          handleGetGroups();
        }
      }
    );

    return {
      currentNotification,
      handleSubmit,
      handleCancel,
      v,
      audiences,
      types,
      groups,
      groupsSelected,
      handleSendNotification,
    };
  },
};
</script>
