import axiosInstance from "./api";

const api = (router) => {
    axiosInstance.interceptors.request.use(
        config => {
            const token = localStorage.getItem('idToken');

            if (token) {
                config.headers.Authorization = `Bearer ${token}`
            }
            return config;
        },
        error => {
            Promise.reject(error)
        });


    axiosInstance.interceptors.response.use(response => {
        return response;
    }, error => {
        if (error.response.status === 401) {
            localStorage.removeItem("idToken")
            router.push({ name: "/" });
            Promise.reject(error)
        }
        return error;
    });
}

export default api;
