<template>
  <div v-if="currentActivity == null">Loading</div>
  <div v-else class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0 d-flex justify-content-between">
            <div><h6>Editar actividad</h6></div>
            <div class="d-flex">
              <a
                class="btn btn-link px-3 mb-0 text-primary"
                href="javascript:;"
              >
                Traducción
              </a>
              <select v-model="selectedLanguage" class="form-select">
                <option
                  v-for="selectItem in languagues"
                  :key="selectItem['key']"
                  :value="selectItem['value']"
                >
                  {{ selectItem.label }}
                </option>
              </select>
            </div>
          </div>
          <div class="card-body">
            <div class="col-sm mx-60">
              <form @submit.prevent="handleSubmit">
                <div class="row">
                  <div class="col-md-6">
                    <label class="form-control-label"> Nombre </label>
                    <input
                      v-model="currentActivity.name"
                      :class="[
                        'form-control',
                        v.name.$error ? 'is-invalid' : '',
                      ]"
                      type="text"
                      placeholder=""
                    />
                    <p v-if="v.name.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.name.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                  <div class="col-md-3">
                    <label class="form-control-label">Tipo</label>
                    <select
                      v-model="currentActivity.activityType"
                      :class="[
                        'form-select',
                        v.activityType.$error ? 'is-invalid' : '',
                      ]"
                      :disabled="selectedLanguage !== 'es'"
                    >
                      <span>{{ currentActivity.activityType }}</span>
                      <option
                        v-for="selectItem in activityTypes"
                        :key="selectItem['@id']"
                        :value="selectItem['@id']"
                      >
                        {{ selectItem.name }}
                      </option>
                    </select>
                    <p
                      v-if="v.activityType.$error"
                      class="invalid-feedback mb-0"
                    >
                      <small>
                        {{ v.activityType.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                  <div class="col-md-3">
                    <label class="form-control-label">Temática</label>
                    <select
                      v-model="currentActivity.activityThematic"
                      class="form-select"
                      :disabled="selectedLanguage !== 'es'"
                    >
                      <option
                        v-for="selectItem in activityThematics"
                        :key="selectItem['@id']"
                        :value="selectItem['@id']"
                      >
                        {{ selectItem.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-6">
                    <label class="form-control-label"> Subtítulo </label>
                    <textarea
                      v-model="currentActivity.subtitle"
                      class="form-control"
                      rows="4"
                    />
                  </div>
                  <div class="col-md-6">
                    <label class="form-control-label"> Descripción </label>
                    <textarea
                      v-model="currentActivity.description"
                      :class="[
                        'form-control',
                        v.description.$error ? 'is-invalid' : '',
                      ]"
                      rows="4"
                    />
                    <p
                      v-if="v.description.$error"
                      class="invalid-feedback mb-0"
                    >
                      <small>
                        {{ v.description.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-3">
                    <label class="form-control-label"> Código </label>
                    <input
                      v-model="currentActivity.code"
                      :class="[
                        'form-control',
                        v.code.$error ? 'is-invalid' : '',
                      ]"
                      type="text"
                      placeholder=""
                      :disabled="selectedLanguage !== 'es'"
                    />
                    <p v-if="v.code.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.code.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                  <div class="col-md-1">
                    <label class="form-control-label"> Aforo </label>
                    <input
                      v-model="currentActivity.capacity"
                      class="form-control"
                      type="number"
                      placeholder=""
                      min="-1"
                      :disabled="selectedLanguage !== 'es'"
                    />
                  </div>
                  <div class="col-md-2">
                    <label class="form-control-label"> Duración </label>
                    <input
                      v-model="currentActivity.duration"
                      class="form-control"
                      type="text"
                      placeholder=""
                      :disabled="selectedLanguage !== 'es'"
                    />
                  </div>
                  <div class="col-md-6">
                    <label class="form-control-label"> Fecha </label>
                    <input
                      v-model="currentActivity.date"
                      :class="[
                        'form-control',
                        v.date.$error ? 'is-invalid' : '',
                      ]"
                      type="datetime-local"
                      placeholder=""
                      :disabled="selectedLanguage !== 'es'"
                    />
                    <p v-if="v.date.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.date.$errors[0].$message }}
                      </small>
                    </p>
                  </div>

                </div>
                                    <div class="row mt-4">
                      <div class="col-md-6">
                        <label class="form-control-label"> Idioma </label>
                        <select
                          v-model="currentActivity.language"
                          class="form-select"
                          :disabled="selectedLanguage !== 'es'"
                        >
                          <option key="es" value="es">Español</option>
                          <option key="en" value="en">Inglés</option>
                          <option key="pt" value="pt">Portugues</option>
                          <option key="ca" value="it">Italiano</option>
                        </select>
                      </div>
                      <div class="col-md-6">
                        <label class="form-control-label"> Traducción Simultanea </label>
                        <select
                          v-model="currentActivity.simultaneousTranslation"
                          class="form-select"
                          :disabled="selectedLanguage !== 'es'"
                        >
                          <option key="es" value="es">Español</option>
                          <option key="en" value="en">Inglés</option>
                          <option key="pt" value="pt">Portugues</option>
                          <option key="ca" value="it">Italiano</option>
                        </select>
                      </div>
                  </div>
                <div class="row mt-4">
                  <div class="col-md-6">
                    <label class="form-control-label"> Organizado por </label>
                    <input
                      v-model="currentActivity.organizer"
                      :class="[
                        'form-control',
                        v.organizer.$error ? 'is-invalid' : '',
                      ]"
                      type="text"
                      placeholder=""
                      :disabled="selectedLanguage !== 'es'"
                    />
                    <p v-if="v.organizer.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.organizer.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                  <div class="col-md-6">
                    <label class="form-control-label"> Audiencia </label>
                    <select
                      v-model="currentActivity.ageLimit"
                      :class="[
                        'form-select',
                        v.ageLimit.$error ? 'is-invalid' : '',
                      ]"
                      :disabled="selectedLanguage !== 'es'"
                    >
                      <option
                        key="Recomendada para adolescentes: de 16 a 18 años"
                        value="Recomendada para adolescentes: de 16 a 18 años"
                      >
                        Recomendada para adolescentes: de 16 a 18 años
                      </option>
                      <option
                        key="Recomendada para jóvenes de más de 25 años"
                        value="Recomendada para jóvenes de más de 25 años"
                      >
                        Recomendada para jóvenes de más de 25 años
                      </option>
                      <option
                        key="Para mayores de 18 años"
                        value="Para mayores de 18 años"
                      >
                        Para mayores de 18 años
                      </option>
                      <option
                        key="Recomendada para todos los públicos"
                        value="Recomendada para todos los públicos"
                      >
                        Recomendada para todos los públicos
                      </option>
                    </select>
                    <p v-if="v.ageLimit.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.ageLimit.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-6">
                    <label class="form-control-label"> Dirección </label>
                    <input
                      ref="streetRef"
                      v-model="currentActivity.address"
                      :class="[
                        'form-control',
                        v.address.$error ? 'is-invalid' : '',
                      ]"
                      type="text"
                      placeholder=""
                      :disabled="selectedLanguage !== 'es'"
                    />
                    <p v-if="v.address.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.address.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                  <div class="col-md-3">
                    <label class="form-control-label"> Latitud </label>
                    <input
                      v-model="currentActivity.latitude"
                      :class="[
                        'form-control',
                        v.latitude.$error ? 'is-invalid' : '',
                      ]"
                      type="text"
                      placeholder=""
                      :disabled="selectedLanguage !== 'es'"
                    />
                    <p v-if="v.latitude.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.latitude.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                  <div class="col-md-3">
                    <label class="form-control-label"> Longitud </label>
                    <input
                      v-model="currentActivity.longitude"
                      :class="[
                        'form-control',
                        v.longitude.$error ? 'is-invalid' : '',
                      ]"
                      type="text"
                      placeholder=""
                      :disabled="selectedLanguage !== 'es'"
                    />
                    <p v-if="v.longitude.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.longitude.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                </div>
                <div
                  v-if="selectedLanguage === 'es'"
                  ref="mapDivEdit"
                  class="mt-4"
                  style="width: 100%; height: 50vh"
                />
                <div class="mt-3">
                  <button
                    type="submit"
                    class="
                      float-end
                      btn btn-primary
                      text-white
                      bg-blue-600
                      rounded-lg
                      border border-primary
                      hover:bg-blue-900
                    "
                  >
                    Guardar
                  </button>
                  <button
                    class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
                    @click="handleCancel"
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */

import { onMounted, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { Loader } from "@googlemaps/js-api-loader";
import { languagues } from "@/utils/config";
import { ElMessage } from "element-plus";

const GOOGLE_MAPS_API_KEY = "AIzaSyDCiyEwDxu5Ts3ktGPqPq3SNIgh8K7i93M";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const mapDivEdit = ref(null);
    const streetRef = ref(null);
    const activityThematics = ref(null);
    const selectedLanguage = ref("es");

    const loader = new Loader({
      apiKey: GOOGLE_MAPS_API_KEY,
      version: "weekly",
      libraries: ["places"],
    });

    const getCurrentActivity = async (id) => {
      const activityData = await store.dispatch("activity/getActivityById", id);
      return activityData;
    };

    const rules = computed(() => {
      return {
        name: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        description: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        address: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        organizer: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        ageLimit: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        date: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        activityType: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        latitude: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        longitude: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        code: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
      };
    });

    const currentActivity = computed(
      () => store.getters["activity/getCurrentActivity"]
    );

    const errorMessageTranslate = computed(() => {
      return store.getters["activity/getErrorMessageTranslate"];
    });

    onMounted(async () => {
      loadMap();
    });

    const loadMap = async () => {
      await store.dispatch("activity/getActivityTypes");
      await getCurrentActivity(route.params.id);

      await loader.load();

      const map = new google.maps.Map(mapDivEdit.value, {
        center: {
          lat: Number(currentActivity.value.latitude),
          lng: Number(currentActivity.value.longitude),
        },
        zoom: 15,
      });

      let markers = [];

      markers.push(
        new google.maps.Marker({
          map,
          title: currentActivity.value.address,
          position: new google.maps.LatLng(
            Number(currentActivity.value.latitude),
            Number(currentActivity.value.longitude)
          ),
        })
      );

      const autocomplete = new google.maps.places.SearchBox(streetRef.value);

      autocomplete.addListener("places_changed", () => {
        const places = autocomplete.getPlaces();

        if (places.length === 0) {
          return;
        }

        // Clear out the old markers
        markers.forEach((marker) => {
          marker.setMap(null);
        });
        markers = [];

        const place = places[0];

        currentActivity.value.address = place.formatted_address;

        currentActivity.value.latitude = place.geometry.location
          .lat()
          .toString();
        currentActivity.value.longitude = place.geometry.location
          .lng()
          .toString();

        map.setCenter(place.geometry.location);

        markers.push(
          new google.maps.Marker({
            map,
            title: place.name,
            position: new google.maps.LatLng(
              place.geometry.location.lat(),
              place.geometry.location.lng()
            ),
          })
        );
      });
    };

    watch(
      () => currentActivity.value && currentActivity.value.activityType,
      () => {
        if (
          activityTypes.value !== null &&
          currentActivity.value !== null &&
          currentActivity.value.activityType !== null
        ) {
          let activityThematic = activityTypes.value.find(
            (activityType) =>
              activityType["@id"] === currentActivity.value.activityType
          );
          if (activityThematic !== undefined) {
            activityThematics.value = activityThematic.activityThematics;
          }
        }
      }
    );

    watch(
      () => selectedLanguage.value,
      () => {
        // if (selectedLanguage.value !== "es") {
        console.log(selectedLanguage.value);
        store.dispatch("activity/getActivityTranslate", {
          id: route.params.id,
          lang: selectedLanguage.value,
        });
        loadMap();
        // }
      }
    );

    const activityTypes = computed(
      () => store.getters["activity/getActivityTypes"]
    );

    const v$ = useVuelidate(rules, currentActivity);
    const v = v$.value;

    const handleSubmit = () => {
      v$.value.$validate();
      if (!v$.value.$invalid) {
        updateActivity(currentActivity.value);
      }
    };

    const handleCancel = () => {
      router.push({ name: "Actividades", params: {} });
    };

    const updateActivity = async (data) => {
      if (selectedLanguage.value === "es") {
        console.log(data);
        try {
          await store.dispatch("activity/edit", data);
          await router.push({ name: "Actividades", params: {} });
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          data["lang"] = selectedLanguage.value;
          let response = await store.dispatch("activity/editTranslate", data);
          console.log(response);
          console.log(errorMessageTranslate);
          ElMessage.success(errorMessageTranslate.value);
        } catch (error) {
          console.log(error);
        }
      }
    };

    return {
      languagues,
      activityTypes,
      currentActivity,
      handleSubmit,
      handleCancel,
      v,
      mapDivEdit,
      streetRef,
      activityThematics,
      selectedLanguage,
    };
  },
};
</script>
