
export const getNotifications = (state) => {
  return state.notifications
}

export const getCurrentNotification = (state) => {
  return state.currentNotification
}

export const getTotalItems = (state) => {
  return state.totalItems
}
