import bootstrap from "bootstrap/dist/js/bootstrap.min.js";

export default () => ({
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    isTransparent: "",
    color: "",
    isNavFixed: false,
    isAbsolute: false,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    navbarFixed:
        "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
    bootstrap,
    nameCurrentElement: null,
    routeParent: {},
})