import moment from "moment";

export const setParticipants = (state, participants) => {
    state.participants = participants
    state.loading = false
}

export const setTotalItems = (state, data) => {
    state.totalItems = data
}

export const setCurrentParticipant = (state, participant) => {

    if (participant) {
        participant.birthday ? participant.birthday = moment(participant.birthday).format('YYYY-MM-DD') : null;

        if (participant.way !== null) {

            participant.goWay = "yes"
            participant.way.dateStart = moment(participant.way.dateStart).format('YYYY-MM-DD')
        } else {
            participant.goWay = "no"
        }
    }

    state.currentParticipant = participant


    state.loading = false
}

export const setRegistrationTypes = (state, registrationTypes) => {
    state.registrationTypes = registrationTypes
    state.loading = false
}

export const setParticipantTypes = (state, participantTypes) => {
    state.participantTypes = participantTypes
    state.loading = false
}

export const setPaymentMethods = (state, paymentMethods) => {
    state.paymentMethods = paymentMethods
    state.loading = false
}

export const setStatuses = (state, statuses) => {
    state.statuses = statuses
    state.loading = false
}

export const setAllergies = (state, allergies) => {
    state.allergies = allergies
    state.loading = false
}

export const setStatusesSelected = (state, statusesSelected) => {
    state.statusesSelected = statusesSelected
    state.loading = false
}

export const removeStatusesSelected = (state, statusesSelected) => {
    state.statusesSelected = state.statusesSelected.filter(status => status['@id'] !== statusesSelected)
}

export const setAllergiesSelected = (state, allergiesSelected) => {
    state.allergiesSelected = allergiesSelected
}

export const removeAllergiesSelected = (state, allergiesSelected) => {
    state.allergiesSelected = state.allergiesSelected.filter(status => status['@id'] !== allergiesSelected)
}

export const setLoading = (state, loading) => {
    state.loading = loading
}

export const setErrorMessage = (state, errorMessage) => {
    state.errorMessage = errorMessage
}