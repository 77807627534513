export const toggleSidebarColor = ({ commit }, payload) => {
    commit("sidebarType", payload);
}
export const setCardBackground = ({ commit }, payload) => {
    commit("cardBackground", payload);
}

export const setNameCurrentElement = ({ commit }, payload) => {
    commit("setNameCurrentElement", payload);
}

export const setRouteParent = ({ commit }, payload) => {
    commit("setRouteParent", payload);
}