import store from '../store';

const isAuthenticatedGuard = async (to, from, next) => {

  if (store.getters["auth/getRole"] === 'ROLE_SUPER_ADMIN') {
    next();
  } else if (store.getters["auth/getRole"] === 'ROLE_ADMIN') {
    next({ name: 'Grupos' });
  } else if (store.getters["auth/getRole"] === 'ROLE_ACTIVITIES_ADMIN') {
    next({ name: 'Actividades' });
  }
}

export default isAuthenticatedGuard;
