import moment from "moment";

export let eventDates = [
  {
    id: moment('2022-08-03').format('YYYY-MM-DD'),
    label: 'Miércoles 03 de Agosto',
  },
  {
    id: moment('2022-08-04').format('YYYY-MM-DD'),
    label: 'Jueves 04 de Agosto',
  },
  {
    id: moment('2022-08-05').format('YYYY-MM-DD'),
    label: 'Viernes 05 de Agosto',
  },
  {
    id: moment('2022-08-06').format('YYYY-MM-DD'),
    label: 'Sábado 06 de Agosto',
  },
  {
    id: moment('2022-08-07').format('YYYY-MM-DD'),
    label: 'Domingo 07 de Agosto',
  }
]

export let accommodationTypes = ["Masculino", "Femenino"]
export let languagues = [{ label: "Español", value: "es" }, { label: "Inglés", value: "en" }, { label: "Portugués", value: "pt" }, { label: "Italiano", value: "it" }]

export const audiences = [
  {
    id: "1",
    name: "Todos",
    value: "todos",
  },
  {
    id: "2",
    name: "Grupos",
    value: "grupos",
  },
  {
    id: "3",
    name: "Peregrinos",
    value: "peregrinos",
  },
  {
    id: "4",
    name: "Monitores",
    value: "monitores",
  },
  {
    id: "5",
    name: "Responsables de Grupo",
    value: "responsables_de_grupo",
  },
  {
    id: "6",
    name: "Sacerdotes",
    value: "sacerdotes",
  },
  {
    id: "7",
    name: "Religiosos",
    value: "religiosos",
  },
  {
    id: "8",
    name: "Consagrados",
    value: "consagrados",
  },
  {
    id: "9",
    name: "Laicos",
    value: "laicos",
  }
];
export const types = [
  {
    id: "notificacion",
    name: "Notificación",
    value: "",
  },
  {
    id: "actividad",
    name: "Actividad",
    value: "actividad",
  },
  {
    id: "noticia",
    name: "Noticia",
    value: "noticia",
  },
  {
    id: "comida",
    name: "Comida",
    value: "comida",
  },
  {
    id: "alojamiento",
    name: "Alojamiento",
    value: "alojamiento",
  },
  {
    id: "saludo",
    name: "Saludo",
    value: "saludo",
  },
  {
    id: "identificación",
    name: "Identificación",
    value: "identificación",
  },
];
