import axios from '@/utils/axios';

export const getPaymentById = async ({ commit }, id) => {

    commit('setCurrentPayment', null);

    await axios({
        method: "GET",
        url: `/payments/${id}`,
    })
        .then(response => {
            if (response.data) {
                const { data } = response;
                commit('setCurrentPayment', data);
            }

        })
        .catch(e => {
            console.log(e);
        });
}


export const getPaymentsByGroup = async ({ commit }, params) => {

    commit('setPayments', []);
    const id = await params.id;
    delete params.id;

    await axios({
        method: "GET",
        url: `/groups/${id}/payments`,
        params: params
    })
        .then(response => {
            if (response.data) {
                const { data } = response;
                commit('setPayments', data["hydra:member"]);
                commit('setTotalItems', data['hydra:totalItems']);
            }

        })
        .catch(e => {
            console.log(e);
        });
}

export const add = async (_, payload) => {

    await axios({
        method: "POST",
        url: '/payments',
        data: payload
    })
        .then(response => {
            console.log(response);
        })
        .catch(e => {
            console.log(e);
        });
};

export const edit = async (_, payload) => {

    return await axios({
        method: "PUT",
        url: '/payments/' + payload['id'],
        data: payload
    })
        .then(response => {
            console.log(response);
        })
        .catch(e => {
            console.log(e);
        });
};

export const deletePayment = async ({ commit }, id) => {

    await axios({
        method: "DELETE",
        url: `/payments/${id}`
    })
        .then(response => {
            console.log(response);
            commit('setLoading', false);
        })
        .catch(e => {
            console.log(e);
        });
};

export const getPaymentsModeTotal = async ({ commit }, groups) => {

    await axios({
        method: "GET",
        url: '/payment_methods'
    })

        .then(response => {
            if (response.data) {
                const { data } = response;
                commit('setPaymentsModeGlobal', data["hydra:member"]);
                commit('addPaymentsGlobal', groups);
            }

        })
        .catch(e => {
            console.log(e);
        });
}