
export const getParticipants = (state) => {
    return state.participants
}

export const getCurrentParticipant = (state) => {
    return state.currentParticipant
}

export const getRegistrationTypes = (state) => {
    return state.registrationTypes
}

export const getParticipantTypes = (state) => {
    return state.participantTypes
}

export const getPaymentMethods = (state) => {
    return state.paymentMethods
}

export const getStatuses = (state) => {
    return state.statuses
}

export const getAllergies = (state) => {
    return state.allergies
}

export const getLoading = (state) => {
    return state.loading
}

export const getStatusesParticipant = (state) => {
    return state.statusesSelected
}

export const getAllergiesParticipant = (state) => {
    return state.allergiesSelected
}

export const getTotalItems = (state) => {
    return state.totalItems
}

export const getErrorMessage = (state) => {
    return state.errorMessage
}
