<template>
  <div v-if="payment == null">Loading</div>
  <div v-else class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0 d-flex justify-content-between">
            <h5 font-weight-bolder>Editar Pago</h5>
          </div>
          <div class="card-body">
            <div class="">
              <form @submit.prevent="handleSubmit">
                <div class="row">
                  <div class="col-md-6">
                    <label for="payment_concept" class="form-control-label">
                      Concepto
                    </label>
                    <input
                      id="payment_concept"
                      v-model="payment.concept"
                      class="form-control"
                      type="text"
                      placeholder=""
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="payment_quantity" class="form-control-label">
                      Cantidad
                    </label>
                    <input
                      v-model="payment.quantity"
                      id="payment_quantity"
                      class="form-control"
                      type="number"
                      placeholder=""
                      min="0"
                    />
                  </div>
                  <div class="col-md-6">
                    <label for="payment_participant" class="form-control-label"
                      >Participante</label
                    >
                    <select
                      v-model="payment.participant"
                      id="payment_participant"
                      class="form-select"
                    >
                      <option :value="null">Ninguno</option>
                      <option
                        v-for="selectItem in participants"
                        :key="selectItem['@id']"
                        :value="selectItem['@id']"
                      >
                        {{ selectItem.firstname }} {{ selectItem.lastname }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="mt-3">
                  <button
                    type="submit"
                    v-on:click="setTab('payments-tab')"
                    class="
                      float-end
                      btn btn-primary
                      text-white
                      bg-blue-600
                      border border-primary
                      rounded-lg
                      hover:bg-blue-900
                    "
                  >
                    Guardar Datos
                  </button>
                  <button
                    class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
                    @click="handleCancel"
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

export default {
  props: {
    setTab: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const groupId = route.params.id;

    const getCurrentPayment = async (id) => {
      const paymentData = await store.dispatch("payment/getPaymentById", id);
      return paymentData;
    };

    const getParticipants = async (params) => {
      const group = await store.dispatch(
        "participant/getParticipantsByGroup",
        params
      );
      return group;
    };

    onMounted(getCurrentPayment(route.params.idPay));
    onMounted(getParticipants({ id: route.params.id }));

    const payment = computed(() => store.getters["payment/getCurrentPayment"]);

    const participants = computed(
      () => store.getters["participant/getParticipants"]
    );

    const updatePayment = async (payment) => {
      try {
        await store.dispatch("payment/edit", payment);
        await router.push({
          name: "Detalle-Grupo",
          params: { id: groupId, tab: "payments" },
        });
      } catch (error) {
        console.log(error);
      }
    };

    const handleSubmit = () => {
      updatePayment(payment.value);
    };

    const handleCancel = () => {
      router.push({
        name: "Detalle-Grupo",
        params: { id: groupId, tab: "payments" },
      });
    };

    return {
      payment,
      participants,
      handleSubmit,
      handleCancel,
    };
  },
};
</script>