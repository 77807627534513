<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pt-1 pb-0">
            <div class="row">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation" @click="selectTab">
                  <button
                    :class="[
                      'nav-link',
                      tab === 'participants' ? 'active' : '',
                    ]"
                    id="participants"
                    data-bs-toggle="tab"
                    data-bs-target="#participants"
                    type="button"
                    role="tab"
                    aria-controls="participants"
                  >
                    Participantes
                  </button>
                </li>
                <li class="nav-item" role="presentation" @click="selectTab">
                  <button
                    :class="['nav-link', tab === 'payments' ? 'active' : '']"
                    id="payments"
                    data-bs-toggle="tab"
                    data-bs-target="#payments"
                    type="button"
                    role="tab"
                    aria-controls="payments"
                    aria-selected="false"
                  >
                    Pagos
                  </button>
                </li>
                <li class="nav-item" role="presentation" @click="selectTab">
                  <button
                    :class="['nav-link', tab === 'foods' ? 'active' : '']"
                    id="foods"
                    data-bs-toggle="tab"
                    data-bs-target="#foods"
                    type="button"
                    role="tab"
                    aria-controls="foods"
                    aria-selected="false"
                  >
                    Comidas
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div class="card-body px-0 pt-0 pb-2">
            <div class="tab-content" id="myTabContent">
              <div
                :class="[
                  'tab-pane fade',
                  tab === 'participants' ? 'show active' : '',
                ]"
                id="participants"
                role="tabpanel"
                aria-labelledby="participants-tab"
              >
                <participant-list
                  :id="groupId"
                  v-if="tab == 'participants'"
                ></participant-list>
              </div>
              <div
                :class="[
                  'tab-pane fade',
                  tab === 'payments' ? 'show active' : '',
                ]"
                id="payments"
                role="tabpanel"
                aria-labelledby="payments-tab"
              >
                <payment-list
                  :id="groupId"
                  v-if="tab == 'payments'"
                ></payment-list>
              </div>
              <div
                :class="[
                  'tab-pane fade',
                  tab === 'foods' ? 'show active' : '',
                ]"
                id="foods"
                role="tabpanel"
                aria-labelledby="foods-tab"
              >
                <food-list
                  :id="groupId"
                  v-if="tab == 'foods'"
                ></food-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import ParticipantList from "../participant/ParticipantList.vue";
import PaymentList from "../payment/PaymentList.vue";
import FoodList from "../food/FoodList.vue";

export default {
  components: { ParticipantList, PaymentList, FoodList },
  setup() {
    const route = useRoute();
    const store = useStore();
    const tab = ref(route.params.tab);

    const groupId = route.params.id;

    const getGroupById = async (id) => {
      const group = await store.dispatch("group/getGroupById", id);
      return group;
    };

    getGroupById(route.params.id);
    const currentGroup = computed(() => store.getters["group/getCurrentGroup"]);

    watch(() => {
      if (currentGroup.value) {
        store.dispatch("theme/setNameCurrentElement", currentGroup.value.name);
        store.dispatch("theme/setRouteParent", {
          route: "/groups",
          name: "Grupos",
        });
      }
    });

    const deleteParticipant = async (participant) => {
      try {
        await store.dispatch("participant/deleteParticipant", participant);
      } catch (error) {
        console.log(error);
      }
    };

    const handleDelete = (item) => {
      if (window.confirm("Are you sure you want to delete this item?")) {
        deleteParticipant(item);
      }
    };

    const selectTab = (e) => {
      tab.value = e.target.id;
    };

    return {
      handleDelete,
      groupId,
      selectTab,
      tab,
    };
  },
};
</script>
