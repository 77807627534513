import moment from "moment";

export const setActivities = (state, activities) => {
    state.activities = activities
    state.loading = false
}

export const setActivityParticipants = (state, activityParticipants) => {
    state.activityParticipants = activityParticipants
    state.loading = false
}

export const setTotalItems = (state, data) => {
    state.totalItems = data
}

export const setTotalItemsParticipants = (state, data) => {
    state.totalItemsParticipants = data
}

export const setCurrentActivity = (state, activity) => {

    if (activity) {
        activity.date ? activity.date = moment(activity.date).format('YYYY-MM-DDTHH:mm') : null;
    }

    state.currentActivity = activity
    state.loading = false
}

export const setActivityTypes = (state, activityTypes) => {
    state.activityTypes = activityTypes
    state.loading = false
}

export const setLoading = (state, loading) => {
    state.loading = loading
}

export const setErrorMessageTranslate = (state, errorMessageTranslate) => {
    state.errorMessageTranslate = errorMessageTranslate
}