import moment from "moment";


export const setGroups = (state, groups) => {
    state.groups = groups
    state.loading = false
}

export const setGroupsTotalParticipants = (state, groups) => {
    state.groupsTotalParticipants = groups
    state.loading = false
}

export const setTotalItems = (state, data) => {
    state.totalItems = data
}

export const setCurrentGroup = (state, group) => {
    if (group) {
        group.greetingsToSantiago ? group.greetingsToSantiago = moment(group.greetingsToSantiago).format('YYYY-MM-DDTHH:mm') : null;
    }
    state.currentGroup = group
    state.loading = false
}

export const setGroupTypes = (state, groupTypes) => {
    state.groupTypes = groupTypes
    state.loading = false
}

export const setLoading = (state, loading) => {
    state.loading = loading
}

export const addPaymentModes = (state, group) => {

    const modesDefault = ['Modo 1', 'Modo 2', 'Modo 3', 'Modo 4']

    modesDefault.map(mode => {
        if(state.paymentsMode.find(paymentMode => paymentMode.mode === mode) === undefined) {      
            let modeTemp = {
                mode: mode,
                total: 0,
                numParticipants: 0
            }
            state.paymentsMode.push(modeTemp)
        }
    })

    state.paymentsMode.sort((a,b) => (a.mode > b.mode) ? 1 : ((b.mode > a.mode) ? -1 : 0))

    state.paymentsMode = [...state.paymentsMode,
        {
            mode: "De Grupo",
            total: group.paymentsByGroup ? group.paymentsByGroup : 0,
            numParticipants: "_"
        },
        {
            mode: "",
            total: group.totalPayments ? group.totalPayments : 0,
            numParticipants: ""
        }
    ]

}

export const setPaymentsMode = (state, paymentsMode) => {
    state.paymentsMode = paymentsMode
    state.loading = false
}