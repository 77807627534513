<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0 d-flex justify-content-between">
            <h5 font-weight-bolder>Nuevo participante</h5>
          </div>
          <div class="card-body">
            <form @submit.prevent="handleSubmit">
              <div class="row">
                <div class="col-md-6">
                  <label for="participant_firstname" class="form-control-label">
                    Nombre
                  </label>
                  <input
                    id="participant_firstname"
                    v-model="newParticipant.firstname"
                    :class="[
                      'form-control',
                      v.firstname.$error ? 'is-invalid' : '',
                    ]"
                    type="text"
                    placeholder=""
                  />
                  <p v-if="v.firstname.$error" class="invalid-feedback mb-0">
                    <small>
                      {{ v.firstname.$errors[0].$message }}
                    </small>
                  </p>
                </div>
                <div class="col-md-6">
                  <label for="participant_lastname" class="form-control-label">
                    Apellidos
                  </label>
                  <input
                    v-model="newParticipant.lastname"
                    id="participant_lastname"
                    :class="[
                      'form-control',
                      v.lastname.$error ? 'is-invalid' : '',
                    ]"
                    type="text"
                    placeholder=""
                  />
                  <p v-if="v.lastname.$error" class="invalid-feedback mb-0">
                    <small>
                      {{ v.lastname.$errors[0].$message }}
                    </small>
                  </p>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-6">
                  <label for="participant_lastname" class="form-control-label">
                    Email
                  </label>
                  <input
                    id="participant_email"
                    v-model="newParticipant.email"
                    :class="[
                      'form-control',
                      v.email.$error ? 'is-invalid' : '',
                    ]"
                    type="email"
                    placeholder=""
                  />
                  <p v-if="v.email.$error" class="invalid-feedback mb-0">
                    <small>
                      {{ v.email.$errors[0].$message }}
                    </small>
                  </p>
                </div>
                <div class="col-md-3">
                  <label for="participant_gender" class="form-control-label"
                    >Genero</label
                  >
                  <select
                    v-model="newParticipant.gender"
                    id="participant_gender"
                    :class="[
                      'form-select',
                      v.gender.$error ? 'is-invalid' : '',
                    ]"
                  >
                    <option
                      v-for="selectItem in genders"
                      :key="selectItem"
                      :value="selectItem"
                    >
                      {{ selectItem }}
                    </option>
                  </select>
                  <p v-if="v.gender.$error" class="invalid-feedback mb-0">
                    <small>
                      {{ v.gender.$errors[0].$message }}
                    </small>
                  </p>
                </div>
                <div class="col-md-3">
                  <label for="participant_dni" class="form-control-label"
                    >DNI</label
                  >
                  <input
                    id="participant_dni"
                    v-model="newParticipant.dni"
                    :class="['form-control', v.dni.$error ? 'is-invalid' : '']"
                    type="text"
                    placeholder=""
                  />
                  <p v-if="v.dni.$error" class="invalid-feedback mb-0">
                    <small>
                      {{ v.dni.$errors[0].$message }}
                    </small>
                  </p>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-3">
                  <label for="participant_birthday" class="form-control-label"
                    >Fecha de nacimiento</label
                  >
                  <input
                    id="participant_birthday"
                    v-model="newParticipant.birthday"
                    :class="[
                      'form-control',
                      v.birthday.$error ? 'is-invalid' : '',
                    ]"
                    type="date"
                    max="2006-12-31"
                  />
                  <p v-if="v.birthday.$error" class="invalid-feedback mb-0">
                    <small>
                      {{ v.birthday.$errors[0].$message }}
                    </small>
                  </p>
                </div>
                <div class="col-md-3">
                  <label
                    for="participant_participantType"
                    class="form-control-label"
                    >Tipo de participación</label
                  >
                  <select
                    v-model="newParticipant.participantType"
                    id="participant_participantType"
                    :class="[
                      'form-select',
                      v.participantType.$error ? 'is-invalid' : '',
                    ]"
                  >
                    <option
                      v-for="selectItem in participantTypes"
                      :key="selectItem['@id']"
                      :value="selectItem['@id']"
                    >
                      {{ selectItem.name }}
                    </option>
                  </select>
                  <p
                    v-if="v.participantType.$error"
                    class="invalid-feedback mb-0"
                  >
                    <small>
                      {{ v.participantType.$errors[0].$message }}
                    </small>
                  </p>
                </div>
                <div class="col-md-3">
                  <label
                    for="participant_registrationType"
                    class="form-control-label"
                    >Tipo de registro</label
                  >
                  <select
                    v-model="newParticipant.registrationType"
                    id="participant_registrationType"
                    :class="[
                      'form-select',
                      v.registrationType.$error ? 'is-invalid' : '',
                    ]"
                  >
                    <option
                      v-for="selectItem in registrationTypes"
                      :key="selectItem['@id']"
                      :value="selectItem['@id']"
                    >
                      {{ selectItem.name }}
                    </option>
                  </select>
                  <p
                    v-if="v.registrationType.$error"
                    class="invalid-feedback mb-0"
                  >
                    <small>
                      {{ v.registrationType.$errors[0].$message }}
                    </small>
                  </p>
                </div>
                <div class="col-md-3">
                  <label
                    for="participant_paymentMethod"
                    class="form-control-label"
                    >Método de pago</label
                  >
                  <select
                    v-model="newParticipant.paymentMethod"
                    id="participant_paymentMethod"
                    :class="[
                      'form-select',
                      v.paymentMethod.$error ? 'is-invalid' : '',
                    ]"
                  >
                    <option
                      v-for="selectItem in paymentMethods"
                      :key="selectItem['@id']"
                      :value="selectItem['@id']"
                    >
                      {{ selectItem.mode }}
                    </option>
                  </select>
                  <p
                    v-if="v.paymentMethod.$error"
                    class="invalid-feedback mb-0"
                  >
                    <small>
                      {{ v.paymentMethod.$errors[0].$message }}
                    </small>
                  </p>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-6">
                  <label
                    for="participant_groupReference"
                    class="form-control-label"
                    >Referencia de grupo</label
                  >
                  <input
                    id="participant_groupReference"
                    v-model="newParticipant.groupReference"
                    class="form-control"
                    type="text"
                    placeholder=""
                  />
                </div>
                <div class="col-md-6">
                  <label class="form-control-label">Estado</label>
                  <div class="d-flex justify-content-between ms-1 mt-1">
                    <div
                      v-for="selectItem in statuses"
                      :key="selectItem['@id']"
                    >
                      <input
                        type="checkbox"
                        :id="selectItem['@id']"
                        v-model="newParticipant.participantStatuses"
                        :value="{ status: selectItem['@id'] }"
                      />
                      <label :for="participant_participantStatuses">{{
                        selectItem.name
                      }}</label>
                    </div>
                  </div>
                  <p v-if="v.participantStatuses.$error" class="invalid-text">
                    <small>
                      {{ v.participantStatuses.$errors[0].$message }}
                    </small>
                  </p>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-6">
                  <label for="participant_diseases" class="form-control-label"
                    >Enfermedades</label
                  >
                  <input
                    id="participant_diseases"
                    v-model="newParticipant.diseases"
                    class="form-control"
                    type="text"
                    placeholder=""
                  />
                </div>
                <div class="col-md-6">
                  <label class="form-control-label">Alergias</label>
                  <div class="d-flex justify-content-between ms-1 mt-1">
                    <div
                      v-for="selectItem in allergies"
                      :key="selectItem['@id']"
                    >
                      <input
                        type="checkbox"
                        :id="selectItem['@id']"
                        v-model="newParticipant.participantAllergies"
                        :value="{ allergy: selectItem['@id'] }"
                      />
                      <label :for="participant_participantAllergies">{{
                        selectItem.name
                      }}</label>
                    </div>
                    <!-- <label>{{ newParticipant.participantAllergies }}</label> -->
                  </div>
                </div>
              </div>
              <div
                v-if="
                  newParticipant.participantAllergies.find(
                    (al) => al.allergy === '/api/allergies/4'
                  ) !== undefined
                "
                class="col mt-4"
              >
                <label
                  for="participant_otherAllergies"
                  class="form-control-label"
                  >Otras alergias</label
                >
                <input
                  id="participant_otherAllergies"
                  v-model="newParticipant.otherAllegies"
                  class="form-control"
                  type="text"
                  placeholder=""
                />
              </div>
              <div class="row mt-3">
                <div class="col-md-6">
                  <h6 class="mb-0">¿Vas a realizar el Camino de Santiago?</h6>
                  <div>
                    <el-radio
                      v-model="newParticipant.goWay"
                      label="yes"
                      size="large"
                      >Si</el-radio
                    >
                    <el-radio
                      v-model="newParticipant.goWay"
                      label="no"
                      size="large"
                      >No</el-radio
                    >
                  </div>
                </div>
              </div>
              <div
                v-if="newParticipant.goWay === 'yes'"
                class="card border mt-1 pb-3"
              >
                <div class="card-header pb-0 pt-3">
                  <h6>Datos del camino</h6>
                </div>
                <div class="card-body pt-0">
                  <div class="row">
                    <div class="col-md-6 mt-2">
                      <label for="way_name" class="form-control-label"
                        >Nombre del camino</label
                      >
                      <select
                        v-model="newParticipant.way.name"
                        id="way_name"
                        class="form-control"
                      >
                        <option
                          v-for="selectItem in ways"
                          :key="selectItem"
                          :value="selectItem"
                        >
                          {{ selectItem }}
                        </option>
                      </select>
                    </div>
                    <div
                      v-if="newParticipant.way.name === 'Otro'"
                      class="col-md-6 mt-2"
                    >
                      <label for="way_name" class="form-control-label">
                        Indicar nombre del camino
                      </label>
                      <input
                        id="way_name"
                        v-model="newParticipant.way.other"
                        class="form-control"
                        type="text"
                        placeholder=""
                      />
                    </div>
                    <div
                      v-if="newParticipant.way.name === 'Otro'"
                      class="col-md-6 mt-2"
                    >
                      <label for="way_place_start" class="form-control-label">
                        Desde donde empieza a caminar
                      </label>
                      <input
                        id="way_place_start"
                        v-model="newParticipant.way.placeStart"
                        class="form-control"
                        type="text"
                        placeholder=""
                      />
                    </div>
                    <div class="col-md-6 mt-2">
                      <label for="way_date_start" class="form-control-label">
                        Fecha de inicio
                      </label>
                      <input
                        id="way_date_start"
                        v-model="newParticipant.way.dateStart"
                        class="form-control"
                        type="date"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12 d-flex">
                  <el-checkbox
                    v-model="newParticipant.isAuthorized"
                    size="large"
                    :class="[v.isAuthorized.$error ? 'is-invalid' : '']"
                  />
                  <!-- <span>{{ newParticipant.isAuthorized }}</span> -->
                  <p class="ms-2 mb-0 mt-1">
                    <small>
                      Como responsable de la entidad solicitante (diócesis,
                      movimiento o congregación anteriormente citada) confirmo
                      que, conforme a la normativa vigente, disponemos de la
                      autorización de la persona inscrita para facilitar sus
                      datos personales, a la Subcomisión para la Juventud e
                      Infancia de la Conferencia Episcopal Española con motivo
                      de la Peregrinación Europea de Jóvenes a Santiago de
                      Compostela PEJ22.
                    </small>
                  </p>
                </div>
              </div>
              <div class="row mx-1">
                <p
                  v-if="newParticipant.isAuthorized == false"
                  class="invalid-text"
                >
                  <small>
                    Debe dar su autorización para continuar con el registro
                  </small>
                </p>
              </div>
              <div class="row mt-3 mx-1">
                <div class="col-md-12 d-flex">
                  <button
                    type="button"
                    class="
                      btn btn-primary
                      bg-white
                      text-primary
                      border border-primary
                    "
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Nota informativa
                  </button>
                  <div
                    class="modal fade"
                    id="exampleModal"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title">
                            DOCUMENTACIÓN QUE DEBÉIS RECOGER
                          </h5>
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div class="modal-body">
                          <span>
                            Esta documentación debéis recogerla, pero no se debe
                            enviar a la CEE a no ser que se os solicite:
                          </span>
                          <div class="mt-4">
                            <div>
                              <span>- Fotocopia del DNI por las dos caras</span>
                            </div>
                            <div>
                              <span>- Autorización de protección de datos</span>
                            </div>
                            <div>
                              <span
                                >- Autorización para los menores de edad</span
                              >
                            </div>
                            <div>
                              <span
                                >- Para los responsables y monitores,
                                Certificado de Delitos de Naturaleza Sexual del
                                Ministerio de Justicia
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="
                              btn btn-primary
                              bg-white
                              text-primary
                              border border-primary
                              mb-0
                            "
                            data-bs-dismiss="modal"
                          >
                            Cerrar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                class="
                  ms-2
                  float-end
                  btn btn-primary
                  text-white
                  bg-blue-600
                  border border-primary
                  rounded-lg
                  hover:bg-blue-900
                "
              >
                Inscribir
              </button>
              <button
                class="
                  float-end
                  btn btn-primary
                  text-danger
                  bg-white
                  border border-danger
                  rounded-lg
                  hover:bg-blue-900
                "
                @click="handleCancel"
              >
                Cancelar
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import moment from "moment";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { ElMessage } from "element-plus";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const groupId = route.params.id;

    store.dispatch("participant/getRegistrationTypes");
    store.dispatch("participant/getParticipantTypes");
    store.dispatch("participant/getPaymentMethods");
    store.dispatch("participant/getStatuses");
    store.dispatch("participant/getAllergies");

    const registrationTypes = computed(
      () => store.getters["participant/getRegistrationTypes"]
    );
    const participantTypes = computed(
      () => store.getters["participant/getParticipantTypes"]
    );
    const paymentMethods = computed(
      () => store.getters["participant/getPaymentMethods"]
    );
    const errorMessage = computed(() => store.getters["participant/getErrorMessage"]);

    const allergies = computed(() => store.getters["participant/getAllergies"]);

    const statuses = computed(() => store.getters["participant/getStatuses"]);

    const newParticipant = ref({
      firstname: "",
      lastname: "",
      email: "",
      gender: "",
      dni: "",
      birthday: "",
      participantType: "",
      registrationType: "",
      paymentMethod: "",
      otherAllergies: null,
      diseases: null,
      participantStatuses: [],
      participantAllergies: [],
      way: {
        name: "",
        other: "",
        placeStart: "",
        dateStart: "",
      },
      password: "dni",
      isAuthorized: "no",
      groupRelated: `/api/groups/${groupId}`,
      groupReference: "",
      goWay: "no",
    });

    const rules = computed(() => {
      return {
        firstname: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        lastname: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        email: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        gender: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        dni: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        birthday: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        participantType: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        registrationType: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        paymentMethod: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        participantStatuses: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        isAuthorized: {
          required: helpers.withMessage("Debe dar su autorización", required),
        },
      };
    });

    const v$ = useVuelidate(rules, newParticipant);
    const v = v$.value;

    const createParticipant = async (participant) => {
      try {
        await store.dispatch("participant/add", participant);
        if (errorMessage.value === null) {
          await router.push({
            name: "Detalle-Grupo",
            params: { id: groupId, tab: "participants" },
          });
        } else {
          ElMessage.error(errorMessage.value);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const handleSubmit = () => {
      if (newParticipant.value.goWay === "yes") {
        if (newParticipant.value.way.dateStart !== "")
          newParticipant.value.way.dateStart = moment(
            newParticipant.value.way.dateStart
          ).format("YYYY-MM-DD");

        if (newParticipant.value.way.name === "Otro")
          newParticipant.value.way.name = newParticipant.value.way.other;
      } else {
        newParticipant.value.way = null;
      }

      if (newParticipant.value.birthday !== "")
        newParticipant.value.birthday = moment(
          newParticipant.value.birthday
        ).format("YYYY-MM-DD");

      v$.value.$validate();

      newParticipant.value.isAuthorized === "no"
        ? (newParticipant.value.isAuthorized = false)
        : newParticipant.value.isAuthorized;

      if (!v$.value.$invalid && newParticipant.value.isAuthorized) {
        createParticipant(newParticipant.value);
      }
    };

    const handleCancel = () => {
      router.push({
        name: "Detalle-Grupo",
        params: { id: groupId, tab: "participants" },
      });
    };

    const ways = [
      "Camino Inglés",
      "Camino Francés",
      "Camino del Norte",
      "Camino Primitivo",
      "Vía de la Plata",
      "Camino Portugués",
      "Variante Espiritual del Camino Portugués",
      "Camino de la Ría Muros-Noia",
      "Camiño Miñoto- Ribeiro",
      "Camino A Orixe",
      "Camino de Invierno",
      "Otro",
    ];

    const genders = ["Femenino", "Masculino"];

    const formLabelAlign = ref({
      name: "",
      region: "",
      type: "",
    });

    return {
      registrationTypes,
      participantTypes,
      paymentMethods,
      newParticipant,
      handleSubmit,
      statuses,
      ways,
      allergies,
      genders,
      handleCancel,
      formLabelAlign,
      v,
    };
  },
};
</script>
