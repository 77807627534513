<template>
  <div v-if="participants === null">Loading</div>
  <div v-else class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0">
            <div class="row mb-2">
              <div class="col">
                <input
                  type="text"
                  class="form-control me-2 w-100"
                  placeholder="Buscar"
                  v-model="search"
                  @keyup="handleSearch"
                />
              </div>
              <div class="col-auto" v-if="role == 'ROLE_SUPER_ADMIN'">
                <router-link
                  :to="{ name: 'Crear-Participante' }"
                  class="
                    btn btn-primary
                    text-white
                    bg-blue-600
                    rounded-lg
                    hover:bg-blue-900
                    mb-0
                    float-end
                  "
                  >Nuevo Participante
                </router-link>
              </div>
            </div>
            <div class="row">
              <h6>Total de participantes: {{ totalItems }}</h6>
            </div>
          </div>
          <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="
                        text-uppercase text-primary text-xs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Nombre
                    </th>
                    <th
                      class="
                        text-uppercase text-primary text-xs
                        font-weight-bolder
                        opacity-7
                        ps-2
                      "
                    >
                      Apellido
                    </th>
                    <th
                      class="
                        text-center text-uppercase text-primary text-xs
                        font-weight-bolder
                        opacity-7
                        ps-2
                      "
                    >
                      DNI
                    </th>
                    <th
                      class="
                        text-center text-uppercase text-primary text-xs
                        font-weight-bolder
                        opacity-7
                        ps-2
                      "
                    >
                      Tipo
                    </th>
                    <th class="text-custom opacity-7"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in participants" :key="item['id']">
                    <td>
                      <div class="d-flex px-2 py-1">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">
                            {{ item["firstname"] }}
                          </h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex px-2 py-1">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">
                            {{ item["lastname"] }}
                          </h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p class="text-center text-xs font-weight-bold mb-0">
                        {{ item["dni"] }}
                      </p>
                    </td>
                    <td>
                      <p class="text-center text-xs font-weight-bold mb-0">
                        {{ item["registrationType"]['name'] }}
                      </p>
                    </td>
                    <td class="text-center">
                      <router-link
                        :to="{
                          name: 'Actualizar-Participante',
                          params: { id: item['id'] },
                        }"
                      >
                        <a
                          class="btn btn-link px-3 mb-0 text-primary"
                          href="javascript:;"
                        >
                          Editar
                        </a>
                      </router-link>
                      <span class="text-primary">|</span>
                      <a
                        class="btn btn-link px-3 mb-0 text-secondary"
                        href="javascript:;"
                        @click="handleDelete(item['id'])"
                      >
                        Borrar
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="float-end my-3 me-4">
              <el-pagination
                background
                v-model:currentPage="currentPage"
                v-model:page-size="pageSize"
                :total="totalItems"
                :page-sizes="[10, 15, 20, 30]"
                layout="sizes, prev, pager, next"
                @current-change="handleGetParticipants"
                @size-change="handleGetParticipants"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ElMessageBox } from "element-plus";

export default {
  setup() {
    const route = useRoute();
    const store = useStore();
    const search = ref("");
    const currentPage = ref(1);
    const pageSize = ref(10);

    const getParticipants = async (params) => {
      const response = await store.dispatch(
        "participant/getParticipantsByGroup",
        params
      );
      return response;
    };

    onMounted(() => {
      handleGetParticipants();
    });

    const participants = computed(
      () => store.getters["participant/getParticipants"]
    );

    const totalItems = computed(
      () => store.getters["participant/getTotalItems"]
    );
const role = computed(()=> store.getters["auth/getRole"]);
    const handleSearch = () => {
      getParticipants({
        id: route.params.id,
        rows: pageSize.value,
        _page: 1,
        search: search.value,
      });
    };

    const handleGetParticipants = () => {
      getParticipants({
        id: route.params.id,
        rows: pageSize.value,
        _page: currentPage.value,
        search: search.value,
      });
    };

    const deleteParticipant = async (participant) => {
      try {
        await store.dispatch("participant/deleteParticipant", participant);
        handleGetParticipants();
      } catch (error) {
        console.log(error);
      }
    };

    const handleDelete = (item) => {
      ElMessageBox.confirm("¿Estás seguro que deseas eliminar el registro?")
        .then(() => {
          deleteParticipant(item);
        })
        .catch(() => {
          // catch error
        });
    };

    return {
      participants,
      handleDelete,
      search,
      handleSearch,
      currentPage,
      pageSize,
      totalItems,
      handleGetParticipants,
      role
    };
  },
};
</script>
