<template>
  <div v-if="currentParticipant == null">Loading</div>
  <div v-else class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0 d-flex justify-content-between">
            <h5 font-weight-bolder>Editar participante</h5>
          </div>
          <div class="card-body">
            <div class="">
              <form @submit.prevent="handleSubmit">
                <div class="row">
                  <div class="col-md-6">
                    <label
                      for="participant_firstname"
                      class="form-control-label"
                    >
                      Nombre
                    </label>
                    <input
                      id="participant_firstname"
                      v-model="currentParticipant.firstname"
                      :class="[
                        'form-control',
                        v.firstname.$error ? 'is-invalid' : '',
                      ]"
                      type="text"
                      placeholder=""
                    />
                    <p v-if="v.firstname.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.firstname.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                  <div class="col-md-6">
                    <label
                      for="participant_lastname"
                      class="form-control-label"
                    >
                      Apellidos
                    </label>
                    <input
                      v-model="currentParticipant.lastname"
                      id="participant_lastname"
                      :class="[
                        'form-control',
                        v.lastname.$error ? 'is-invalid' : '',
                      ]"
                      type="text"
                      placeholder=""
                    />
                    <p v-if="v.lastname.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.lastname.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-6">
                    <label
                      for="participant_lastname"
                      class="form-control-label"
                    >
                      Email
                    </label>
                    <input
                      id="participant_email"
                      v-model="currentParticipant.email"
                      :class="[
                        'form-control',
                        v.email.$error ? 'is-invalid' : '',
                      ]"
                      type="email"
                      placeholder=""
                    />
                    <p v-if="v.email.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.email.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                  <div class="col-md-3">
                    <label for="participant_gender" class="form-control-label"
                      >Genero</label
                    >
                    <select
                      v-model="currentParticipant.gender"
                      id="participant_gender"
                      :class="[
                        'form-select',
                        v.gender.$error ? 'is-invalid' : '',
                      ]"
                    >
                      <option
                        v-for="selectItem in genders"
                        :key="selectItem"
                        :value="selectItem"
                      >
                        {{ selectItem }}
                      </option>
                    </select>
                    <p v-if="v.gender.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.gender.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                  <div class="col-md-3">
                    <label for="participant_dni" class="form-control-label"
                      >DNI</label
                    >
                    <input
                      id="participant_dni"
                      v-model="currentParticipant.dni"
                      :class="[
                        'form-control',
                        v.dni.$error ? 'is-invalid' : '',
                      ]"
                      type="text"
                      placeholder=""
                    />
                    <p v-if="v.dni.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.dni.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-3">
                    <label for="participant_birthday" class="form-control-label"
                      >Fecha de nacimiento</label
                    >
                    <input
                      id="participant_birthday"
                      v-model="currentParticipant.birthday"
                      :class="[
                        'form-control',
                        v.birthday.$error ? 'is-invalid' : '',
                      ]"
                      type="date"
                      placeholder=""
                    />
                    <p v-if="v.birthday.$error" class="invalid-feedback mb-0">
                      <small>
                        {{ v.birthday.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                  <div class="col-md-3">
                    <label
                      for="participant_participantType"
                      class="form-control-label"
                      >Tipo de participación</label
                    >
                    <select
                      v-model="currentParticipant.participantType"
                      id="participant_participantType"
                      :class="[
                        'form-select',
                        v.gender.$error ? 'is-invalid' : '',
                      ]"
                    >
                      <option
                        v-for="selectItem in participantTypes"
                        :key="selectItem['@id']"
                        :value="selectItem['@id']"
                      >
                        {{ selectItem.name }}
                      </option>
                    </select>
                    <p
                      v-if="v.participantType.$error"
                      class="invalid-feedback mb-0"
                    >
                      <small>
                        {{ v.participantType.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                  <div class="col-md-3">
                    <label
                      for="participant_registrationType"
                      class="form-control-label"
                      >Tipo de registro</label
                    >
                    <select
                      v-model="currentParticipant.registrationType"
                      id="participant_registrationType"
                      :class="[
                        'form-select',
                        v.gender.$error ? 'is-invalid' : '',
                      ]"
                    >
                      <option
                        v-for="selectItem in registrationTypes"
                        :key="selectItem['@id']"
                        :value="selectItem['@id']"
                      >
                        {{ selectItem.name }}
                      </option>
                    </select>
                    <p
                      v-if="v.registrationType.$error"
                      class="invalid-feedback mb-0"
                    >
                      <small>
                        {{ v.registrationType.$errors[0].$message }}
                      </small>
                    </p>
                  </div>
                  <div class="col-md-3">
                    <label
                      for="participant_paymentMethod"
                      class="form-control-label"
                      >Método de pago</label
                    >
                    <select
                      v-model="currentParticipant.paymentMethod"
                      id="participant_paymentMethod"
                      :class="[
                        'form-select',
                      ]"
                    >
                      <option
                        v-for="selectItem in paymentMethods"
                        :key="selectItem['@id']"
                        :value="selectItem['@id']"
                      >
                        {{ selectItem.mode }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-6">
                    <label
                      for="participant_groupReference"
                      class="form-control-label"
                      >Referencia de grupo</label
                    >
                    <input
                      id="participant_groupReference"
                      v-model="currentParticipant.groupReference"
                      class="form-control"
                      type="text"
                      placeholder=""
                    />
                  </div>
                  <div class="col-md-6">
                    <label class="form-control-label">Estado</label>
                    <div class="d-flex justify-content-between ms-1 mt-1">
                      <div
                        v-for="selectItem in statuses"
                        :key="selectItem['@id']"
                      >
                        <input
                          type="checkbox"
                          :id="selectItem['@id']"
                          :value="selectItem['@id']"
                          :checked="
                            currentParticipant.participantStatuses &&
                            currentParticipant.participantStatuses.find(
                              (st) => st['status'] == selectItem['@id']
                            )
                          "
                          @change="selectStatus"
                        />
                        <label>{{ selectItem["name"] }}</label>
                      </div>
                    </div>
                    <!-- <p v-if="v.participantStatuses.$error" class="invalid-text">
                      <small>
                        {{ v.participantStatuses.$errors[0].$message }}
                      </small>
                    </p> -->
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-6">
                    <label for="participant_diseases" class="form-control-label"
                      >Enfermedades</label
                    >
                    <input
                      id="participant_diseases"
                      v-model="currentParticipant.diseases"
                      class="form-control"
                      type="text"
                      placeholder=""
                    />
                  </div>
                  <div class="col-md-6">
                    <label class="form-control-label">Alergias</label>
                    <div class="d-flex justify-content-between ms-1 mt-1">
                      <div
                        v-for="selectItem in allergies"
                        :key="selectItem['@id']"
                      >
                        <input
                          type="checkbox"
                          :id="selectItem['@id']"
                          :value="selectItem['@id']"
                          :checked="
                            currentParticipant.participantAllergies &&
                            currentParticipant.participantAllergies.find(
                              (al) => al['allergy'] == selectItem['@id']
                            )
                          "
                          @change="selectAllergy"
                        />
                        <label>{{ selectItem["name"] }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    allergiesParticipantAll &&
                    allergiesParticipantAll.find(
                      (al) => al.allergy === '/api/allergies/4'
                    ) !== undefined
                  "
                  class="col mt-4"
                >
                  <label
                    for="participant_otherAllergies"
                    class="form-control-label"
                    >Otras alergias</label
                  >
                  <input
                    id="participant_otherAllergies"
                    v-model="currentParticipant.otherAllegies"
                    class="form-control"
                    type="text"
                    placeholder=""
                  />
                </div>
                <div class="row mt-3">
                  <div class="col-md-6">
                    <h6 class="mb-0">¿Vas a realizar el Camino de Santiago?</h6>
                    <div>
                      <el-radio
                        v-model="currentParticipant.goWay"
                        label="yes"
                        size="large"
                        >Si</el-radio
                      >
                      <el-radio
                        v-model="currentParticipant.goWay"
                        label="no"
                        size="large"
                        >No</el-radio
                      >
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    currentParticipant.goWay === 'yes' &&
                    currentParticipant.way !== null
                  "
                  class="card border mt-4 pb-3"
                >
                  <div class="card-header pb-0 pt-3">
                    <h6>Datos del camino</h6>
                  </div>
                  <div class="card-body pt-0">
                    <div class="row">
                      <div class="col-md-6 mt-2">
                        <label for="way_name" class="form-control-label"
                          >Nombre del camino</label
                        >
                        <select
                          v-model="currentParticipant.way.name"
                          id="way_name"
                          class="form-select"
                        >
                          <option
                            v-for="selectItem in ways"
                            :key="selectItem"
                            :value="selectItem"
                          >
                            {{ selectItem }}
                          </option>
                        </select>
                      </div>
                      <div
                        v-if="currentParticipant.way.name === 'Otro'"
                        class="col-md-6 mt-2"
                      >
                        <label for="way_name" class="form-control-label">
                          Indicar nombre del camino
                        </label>
                        <input
                          id="way_name"
                          v-model="currentParticipant.way.other"
                          class="form-control"
                          type="text"
                          placeholder=""
                        />
                      </div>
                      <div
                        v-if="currentParticipant.way.name === 'Otro'"
                        class="col-md-6 mt-2"
                      >
                        <label for="way_place_start" class="form-control-label">
                          Desde donde empieza a caminar
                        </label>
                        <input
                          id="way_place_start"
                          v-model="currentParticipant.way.placeStart"
                          class="form-control"
                          type="text"
                          placeholder=""
                        />
                      </div>
                      <div class="col-md-6 mt-2">
                        <label for="way_date_start" class="form-control-label">
                          Fecha de inicio
                        </label>
                        <input
                          id="way_date_start"
                          v-model="currentParticipant.way.dateStart"
                          class="form-control"
                          type="date"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-3">
                  <button
                    type="submit"
                    class="
                      float-end
                      btn btn-primary
                      text-white
                      bg-blue-600
                      border border-primary
                      rounded-lg
                      hover:bg-blue-900
                    "
                  >
                    Guardar Cambios
                  </button>
                  <button
                    class="
                      me-2
                      float-end
                      btn btn-primary
                      text-danger
                      bg-white
                      border border-danger
                      rounded-lg
                      hover:bg-blue-900
                    "
                    @click="handleCancel"
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { ref, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import moment from "moment";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { ElMessage } from "element-plus";

export default {
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const ways = [
      "Camino Inglés",
      "Camino Francés",
      "Camino del Norte",
      "Camino Primitivo",
      "Vía de la Plata",
      "Camino Portugués",
      "Variante Espiritual del Camino Portugués",
      "Camino de la Ría Muros-Noia",
      "Camiño Miñoto- Ribeiro",
      "Camino A Orixe",
      "Camino de Invierno",
      "Otro",
    ];

    const genders = ["Femenino", "Masculino"];

    const rules = computed(() => {
      return {
        firstname: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        lastname: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        email: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        gender: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        dni: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        birthday: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        participantType: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        registrationType: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
        // paymentMethod: {
        //   required: helpers.withMessage("Este campo es obligatorio", required),
        // },
        // participantStatuses: {
        //   required: helpers.withMessage("Este campo es obligatorio", required),
        // },
        isAuthorized: {
          required: helpers.withMessage("Este campo es obligatorio", required),
        },
      };
    });

    const getCurrentParticipant = async (id) => {
      const participantData = await store.dispatch(
        "participant/getCurrentParticipant",
        id
      );
      return participantData;
    };

    const getParticipantStatuses = async (id) => {
      const participantStatuses = await store.dispatch(
        "participant/getParticipantStatuses",
        id
      );
      return participantStatuses;
    };

    const getParticipantAllergies = async (id) => {
      const participantAllergies = await store.dispatch(
        "participant/getParticipantAllergies",
        id
      );
      return participantAllergies;
    };

    onMounted(getCurrentParticipant(route.params.id));
    onMounted(getParticipantStatuses(route.params.id));
    onMounted(getParticipantAllergies(route.params.id));

    const currentParticipant = computed(
      () => store.getters["participant/getCurrentParticipant"]
    );

    store.dispatch("participant/getRegistrationTypes");
    store.dispatch("participant/getParticipantTypes");
    store.dispatch("participant/getPaymentMethods");
    store.dispatch("participant/getStatuses");
    store.dispatch("participant/getAllergies");

    const registrationTypes = computed(
      () => store.getters["participant/getRegistrationTypes"]
    );
    const participantTypes = computed(
      () => store.getters["participant/getParticipantTypes"]
    );
    const paymentMethods = computed(
      () => store.getters["participant/getPaymentMethods"]
    );

    const statusesParticipantAll = computed(
      () => store.getters["participant/getStatusesParticipant"]
    );

    const allergiesParticipantAll = computed(
      () => store.getters["participant/getAllergiesParticipant"]
    );

    const errorMessage = computed(
      () => store.getters["participant/getErrorMessage"]
    );

    const allergies = computed(() => store.getters["participant/getAllergies"]);
    const statuses = computed(() => store.getters["participant/getStatuses"]);
    const loading = computed(() => store.getters["participant/getLoading"]);

    const statusesParticipant = ref(statusesParticipantAll);
    const v$ = useVuelidate(rules, currentParticipant);
    const v = v$.value;

    watch(
      () => currentParticipant.value && currentParticipant.value.goWay,
      (goWay, prevGoWay) => {
        if (
          prevGoWay === "no" &&
          goWay === "yes" &&
          currentParticipant.value.way === null
        ) {
          currentParticipant.value.way = {
            name: "",
            other: "",
            placeStart: "",
            dateStart: "",
          };
        }
      }
    );

    const handleSubmit = () => {
      if (currentParticipant.value.way !== null) {
        if (currentParticipant.value.way.name === "Otro")
          currentParticipant.value.way.name =
            currentParticipant.value.way.other;

        if (currentParticipant.value.way.dateStart !== "")
          currentParticipant.value.way.dateStart = moment(
            currentParticipant.value.way.dateStart
          ).format("YYYY-MM-DD");
      }

      if (
        currentParticipant.value !== null &&
        currentParticipant.value.birthday !== ""
      )
        currentParticipant.value.birthday = moment(
          currentParticipant.value.birthday
        ).format("YYYY-MM-DD");

      v$.value.$validate();

      currentParticipant.value.isAuthorized === "no"
        ? (currentParticipant.value.isAuthorized = false)
        : currentParticipant.value.isAuthorized;

      if (!v$.value.$invalid && currentParticipant.value.isAuthorized) {
        updateParticipant(currentParticipant.value);
      }
    };

    const updateParticipant = async (participant) => {
      try {
        await store.dispatch("participant/edit", participant);
        if (errorMessage.value === null) {
          await router.push({
            name: "Detalle-Grupo",
            params: { id: participant.groupRelated.id, tab: "participants" },
          });
        } else {
          ElMessage.error(errorMessage.value);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const arrayStatus = (values) => {
      const arr = [];
      values.forEach((status) => {
        arr.push(status["@id"]);
      });
      return arr;
    };

    const selectStatus = async (e) => {
      if (e.target.checked) {
        const statusTemp = {
          participant: currentParticipant.value["@id"],
          status: e.target.value,
        };
        await store.dispatch("participant/addStatus", statusTemp);
        await getParticipantStatuses(route.params.id);
      } else {
        const statusTemp = statusesParticipant.value.find(
          (st) => st.status === e.target.value
        );

        store.dispatch("participant/removeStatus", statusTemp);
      }
    };

    const selectAllergy = async (e) => {
      if (e.target.checked) {
        const allergyTemp = {
          participant: currentParticipant.value["@id"],
          allergy: e.target.value,
        };
        await store.dispatch("participant/addAllergy", allergyTemp);
        await getParticipantAllergies(route.params.id);
      } else {
        const allergyTemp = allergiesParticipantAll.value.find(
          (al) => al.allergy === e.target.value
        );

        store.dispatch("participant/removeAllergy", allergyTemp);
      }
    };

    const handleCancel = () => {
      router.push({
        name: "Detalle-Grupo",
        params: {
          id: currentParticipant.value.groupRelated.id,
          tab: "participants",
        },
      });
    };

    return {
      registrationTypes,
      participantTypes,
      paymentMethods,
      currentParticipant,
      handleSubmit,
      statuses,
      ways,
      allergies,
      loading,
      arrayStatus,
      genders,
      selectStatus,
      statusesParticipant,
      selectAllergy,
      handleCancel,
      allergiesParticipantAll,
      v,
    };
  },
};
</script>
