export const setAccommodations = (state, accommodations) => {
    state.accommodations = accommodations
    state.loading = false
}

export const setMaleAccommodations = (state, accommodations) => {
    state.maleAccommodations = accommodations
    state.loading = false
}

export const setFemaleAccommodations = (state, accommodations) => {
    state.femaleAccommodations = accommodations
    state.loading = false
}

export const setTotalItems = (state, data) => {
    state.totalItems = data
}

export const setCurrentAccommodation = (state, accommodation) => {

    state.currentAccommodation = accommodation
    state.loading = false
}

export const setLoading = (state, loading) => {
    state.loading = loading
}