
export const getGroups = (state) => {
    return state.groups
}

export const getGroupsTotalParticipants = (state) => {
    return state.groupsTotalParticipants
}

export const getCurrentGroup = (state) => {
    return state.currentGroup
}

export const getGroupTypes = (state) => {
    return state.groupTypes
}

export const getPaymentsMode = (state) => {
    return state.paymentsMode
}

export const getTotalItems = (state) => {
    return state.totalItems
}