<template>
  <div v-if="notifications === null">Loading</div>
  <div v-else class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0">
            <div class="row mb-2">
              <div class="col">
                <div class="d-flex">
                  <input
                    type="text"
                    class="form-control me-3"
                    placeholder="Buscar"
                    v-model="search"
                    @keyup="handleSearch"
                  />
                </div>
              </div>
              <div class="col-auto">
                <div class="d-flex">
                  <div>
                    <router-link
                      :to="{ name: 'Crear-Notificacion' }"
                      class="
                        btn btn-primary
                        text-white
                        bg-blue-600
                        rounded-lg
                        hover:bg-blue-900
                        ms-3
                      "
                      >Crear notificación
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-0">
              <table class="table align-items-left mb-0">
                <thead>
                  <tr>
                    <th
                      class="
                        text-uppercase text-primary text-xs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Título
                    </th>
                    <th
                      class="
                        text-uppercase text-primary text-xs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Tipo
                    </th>
                    <th
                      class="
                        text-uppercase text-primary text-xs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Mensaje
                    </th>
                    <th
                      class="
                        text-uppercase text-primary text-xs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Audiencia
                    </th>
                    <th
                      class="
                        text-uppercase text-primary text-xs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Fecha de creación
                    </th>
                    <th
                      class="
                        text-uppercase text-primary text-xs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Fecha de envio
                    </th>
                    <th class="text-custom opacity-7"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in notifications" :key="item['id']">
                    <td>
                      <div class="d-flex px-3 py-2">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">
                            {{ item["title"] }}
                          </h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0 px-3 py-2">
                        {{ item["type"] }}
                      </p>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0 px-3 py-2">
                        {{
                          item["message"].length > 50
                            ? item["message"].substring(0, 50) + "..."
                            : item["message"]
                        }}
                      </p>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0 px-3 py-2">
                        {{ item["audience"] }}
                      </p>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0 px-3 py-2">
                        {{
                          moment(item["createdAt"]).format("DD/MM/YYYY HH:mm")
                        }}
                      </p>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0 px-3 py-2">
                        {{
                          item["sentAt"] != null
                            ? moment(item["sentAt"]).format("DD/MM/YYYY HH:mm")
                            : "--"
                        }}
                      </p>
                    </td>
                    <td class="float-end">
                      <a
                        v-if="item['sentAt'] == null"
                        class="btn btn-link px-3 mb-0 text-primary"
                        @click.prevent="handleSend(item)"
                      >
                        Enviar
                      </a>
                      <span v-if="item['sentAt'] == null" class="text-primary"
                        >|</span
                      >
                      <router-link
                        :to="{
                          name: 'Actualizar-Notificacion',
                          params: { id: item['id'] },
                        }"
                        v-if="item['sentAt'] == null"
                      >
                        <a class="btn btn-link px-3 mb-0 text-secondary">
                          Editar
                        </a>
                      </router-link>
                      <router-link
                        :to="{
                          name: 'Actualizar-Notificacion',
                          params: { id: item['id'] },
                        }"
                        v-else
                      >
                        <a
                          class="btn btn-link px-3 mb-0 text-secondary"
                          href="javascript:;"
                        >
                          Ver
                        </a>
                      </router-link>
                      <span class="text-primary">|</span>
                      <a
                        class="btn btn-link px-3 mb-0 text-primary"
                        href="javascript:;"
                        @click="handleDelete(item)"
                      >
                        Borrar
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="float-end my-3 me-4">
              <el-pagination
                background
                v-model:currentPage="currentPage"
                v-model:page-size="pageSize"
                :total="totalItems"
                :page-sizes="[10, 15, 20, 30]"
                layout="sizes, prev, pager, next"
                @current-change="handleGetNotifications"
                @size-change="handleGetNotifications"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import { ElMessageBox } from "element-plus";
import moment from "moment";

export default {
  setup() {
    const store = useStore();
    const currentPage = ref(1);
    const pageSize = ref(10);
    const search = ref("");

    const getNotifications = async (params) => {
      const response = await store.dispatch(
        "notification/getNotifications",
        params
      );
      return response;
    };

    onMounted(() => {
      handleGetNotifications();

      store.dispatch("theme/setRouteParent", {});
      store.dispatch("theme/setNameCurrentElement", null);
    });

    const notifications = computed(
      () => store.getters["notification/getNotifications"]
    );
    const totalItems = computed(
      () => store.getters["notification/getTotalItems"]
    );

    const handleGetNotifications = () => {
      getNotifications({
        rows: pageSize.value,
        _page: currentPage.value,
        // name: search.value,
      });
    };

    const handleSearch = () => {
      getNotifications({
        rows: pageSize.value,
        _page: 1,
        // name: search.value,
      });
    };

    const deleteNotification = async (notification) => {
      try {
        await store.dispatch("notification/deleteNotification", notification);
        handleGetNotifications();
      } catch (error) {
        console.log(error);
      }
    };

    const sendNotificationNow = async (notification) => {
      try {
        await store.dispatch("notification/sendNotificationNow", notification);
        handleGetNotifications();
      } catch (error) {
        console.log(error);
      }
    };

    const handleDelete = (item) => {
      if (item.sentAt !== null) {
        ElMessageBox(
          "Esta notificación ha sido enviada, no se puede eliminar"
        ).then(() => {});
      } else {
        ElMessageBox.confirm(
          "¿Estás seguro que deseas eliminar el registro?"
        ).then(() => {
          deleteNotification(item.id);
        });
      }
    };

    const handleSend = (item) => {
      ElMessageBox.confirm("¿Estás seguro que deseas enviar la notificación?")
        .then(() => {
          sendNotificationNow(item);
        })
        .catch(() => {
          // catch error
        });
    };

    return {
      notifications,
      handleDelete,
      currentPage,
      pageSize,
      totalItems,
      handleGetNotifications,
      search,
      handleSearch,
      handleSend,
      moment,
    };
  },
};
</script>
